import React from 'react';
import { CourseOrigin } from '.';
import { CourseDTO } from '../../DTO/CourseDTO';
import { SchoolDTO } from '../../DTO/SchoolDTO';
import { VerdictDTO } from '../../DTO/VerdictDTO';
import { ApplicationStatus, EntityState } from '../../util/enumerations';

export interface IApplicationHandlers {
	setYear: (year: number) => void;
	setMasterProgram: (masterTrackId: number) => void;
	handleSelectSchool: (school: SchoolDTO) => void;
	saveApplication: () => void;
	setApplicationStatus: (toStatus: ApplicationStatus) => void;
	giveVerdict: (idx: number, verdict: VerdictDTO) => void;
	saveComment: (idx: number, externalUrl: string) => void;
	saveLink: (idx: number, externalUrl: string) => void;
	addCourse: (idx: number, key: CourseOrigin) => () => void;
	removeCourse: (
		rowIdx: number,
		key: CourseOrigin
	) => (courseIdx: number, courseId: number) => void;
	updateCourse: (
		rowIdx: number,
		key: CourseOrigin
	) => (courseIdx: number, id: number, oldId: number) => void;
	updateInformationRow: (rowIdx: number, text: string, idx: number) => void;
	updateInformationRowUrl: (rowIdx: number, url: string, idx: number) => void;
	removeRow: (idx: number, toState?: EntityState) => void;
	addRow: () => void;
}

export class ApplicationHandlers {
	setYear: (year: number) => void;
	setMasterProgram: (masterTrackId: number) => void;
	handleSelectSchool: (school: SchoolDTO) => void;
	saveApplication: () => void;
	setApplicationStatus: (toStatus: ApplicationStatus) => void;
	giveVerdict: (idx: number, verdict: VerdictDTO) => void;
	saveComment: (idx: number, externalUrl: string) => void;
	saveLink: (idx: number, externalUrl: string) => void;
	addCourse: (idx: number, key: CourseOrigin) => () => void;
	removeCourse: (
		rowIdx: number,
		key: CourseOrigin
	) => (courseIdx: number, courseId: number) => void;
	updateCourse: (
		rowIdx: number,
		key: CourseOrigin
	) => (courseIdx: number, id: number, oldId: number) => void;
	updateInformationRow: (rowIdx: number, text: string, idx: number) => void;
	updateInformationRowUrl: (rowIdx: number, url: string, idx: number) => void;
	removeRow: (idx: number, toState?: EntityState) => void;
	addRow: () => void;

	constructor(args: {
		setYear: (year: number) => void;
		setMasterProgram: (masterTrackId: number) => void;
		handleSelectSchool: (school: SchoolDTO) => void;
		saveApplication: () => void;
		setApplicationStatus: (toStatus: ApplicationStatus) => void;
		giveVerdict: (idx: number, verdict: VerdictDTO) => void;
		saveComment: (idx: number, externalUrl: string) => void;
		saveLink: (idx: number, externalUrl: string) => void;
		addCourse: (idx: number, key: CourseOrigin) => () => void;
		removeCourse: (
			rowIdx: number,
			key: CourseOrigin
		) => (courseIdx: number, courseId: number) => void;
		updateCourse: (
			rowIdx: number,
			key: CourseOrigin
		) => (courseIdx: number, id: number, oldId: number) => void;
		updateInformationRow: (
			rowIdx: number,
			text: string,
			idx: number
		) => void;
		updateInformationRowUrl: (
			rowIdx: number,
			url: string,
			idx: number
		) => void;
		removeRow: (idx: number, toState?: EntityState) => void;
		addRow: () => void;
	}) {
		this.setYear = args.setYear;
		this.setMasterProgram = args.setMasterProgram;
		this.handleSelectSchool = args.handleSelectSchool;
		this.saveApplication = args.saveApplication;
		this.setApplicationStatus = args.setApplicationStatus;
		this.saveComment = args.saveComment;
		this.saveLink = args.saveLink;
		this.addCourse = args.addCourse;
		this.removeCourse = args.removeCourse;
		this.updateCourse = args.updateCourse;
		this.updateInformationRow = args.updateInformationRow;
		this.updateInformationRowUrl = args.updateInformationRowUrl;
		this.removeRow = args.removeRow;
		this.addRow = args.addRow;
		this.giveVerdict = args.giveVerdict;
	}
}

export class MockApplicationHandlers implements IApplicationHandlers {
	setYear: (year: number) => void;
	setMasterProgram: (masterTrackId: number) => void;
	handleSelectSchool: (school: SchoolDTO) => void;
	saveApplication: () => void;
	setApplicationStatus: (toStatus: ApplicationStatus) => void;
	giveVerdict: (idx: number, verdict: VerdictDTO) => void;
	saveComment: (idx: number, externalUrl: string) => void;
	saveLink: (idx: number, externalUrl: string) => void;
	addCourse: (idx: number, key: CourseOrigin) => () => void;
	removeCourse: (
		rowIdx: number,
		key: CourseOrigin
	) => (courseIdx: number, courseId: number) => void;
	updateCourse: (
		rowIdx: number,
		key: CourseOrigin
	) => (courseIdx: number, id: number, oldId: number) => void;
	updateInformationRow: (rowIdx: number, text: string, idx: number) => void;
	updateInformationRowUrl: (rowIdx: number, url: string, idx: number) => void;
	removeRow: (idx: number, toState?: EntityState) => void;
	addRow: () => void;

	constructor(overrides?: {
		setYear?: (year: number) => void;
		setMasterProgram?: (masterTrackId: number) => void;
		handleSelectSchool?: (school: SchoolDTO) => void;
		saveApplication?: () => void;
		setApplicationStatus?: (toStatus: ApplicationStatus) => void;
		giveVerdict?: (idx: number, verdict: VerdictDTO) => void;
		saveComment?: (idx: number, externalUrl: string) => void;
		saveLink?: (idx: number, externalUrl: string) => void;
		addCourse?: (idx: number, key: CourseOrigin) => () => void;
		removeCourse?: (
			rowIdx: number,
			key: CourseOrigin
		) => (courseIdx: number, courseId: number) => void;
		updateCourse?: (
			rowIdx: number,
			key: CourseOrigin
		) => (courseIdx: number, id: number, oldId: number) => void;
		updateInformationRow?: (
			rowIdx: number,
			text: string,
			idx: number
		) => void;
		updateInformationRowUrl?: (
			rowIdx: number,
			url: string,
			idx: number
		) => void;
		removeRow?: (idx: number, toState?: EntityState) => void;
		addRow?: () => void;
	}) {
		this.setYear = overrides?.setYear || ((y: number) => null);
		this.setMasterProgram =
			overrides?.setMasterProgram || ((mt: number) => null);
		this.handleSelectSchool =
			overrides?.handleSelectSchool || ((s: SchoolDTO) => null);
		this.saveApplication = overrides?.saveApplication || (() => null);
		this.setApplicationStatus =
			overrides?.setApplicationStatus || ((s: ApplicationStatus) => null);
		this.giveVerdict =
			overrides?.giveVerdict || ((i: number, v: VerdictDTO) => null);
		this.saveComment =
			overrides?.saveComment || ((i: number, u: string) => null);
		this.saveLink = overrides?.saveLink || ((i: number, u: string) => null);
		this.addCourse = (i: number, key: CourseOrigin) => () => null;
		this.removeCourse =
			(r: number, key: CourseOrigin) => (c: number, cId: number) =>
				null;
		this.updateCourse =
			(rowIdx: number, key: CourseOrigin) =>
			(c: number, cId: number, oId: number) =>
				null;
		this.updateInformationRow =
			overrides?.updateInformationRow ||
			((r: number, t: string, i: number) => null);
		this.updateInformationRowUrl =
			overrides?.updateInformationRowUrl ||
			((r: number, u: string, i: number) => null);
		this.removeRow =
			overrides?.removeRow || ((i: number, s?: EntityState) => null);

		this.addRow = overrides?.addRow || (() => null);
	}
}
