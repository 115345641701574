import React from 'react';
import { SchoolDTO } from '../DTO/SchoolDTO';
import { APIService } from '../services/apiService';

type State = SchoolDTO[] | null;
type Handlers = {
	saveSchool: (newSchool: SchoolDTO) => Promise<SchoolDTO>;
};

const SchoolContext = React.createContext<State | undefined>(undefined);
const SchoolUpdateContext = React.createContext<Handlers | undefined>(
	undefined
);

enum ActionType {
	SET_SCHOOLS,
	SAVE_SCHOOL,
}

type Action =
	| {
			type: ActionType.SET_SCHOOLS;
			payload: SchoolDTO[];
	  }
	| {
			type: ActionType.SAVE_SCHOOL;
			payload: SchoolDTO;
	  };

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case ActionType.SET_SCHOOLS:
			return action.payload;
		case ActionType.SAVE_SCHOOL:
			if (state) {
				let found = false;
				const newSchools = state.map((s) => {
					if (s.id === action.payload.id) {
						found = true;
						return action.payload;
					} else return s;
				});
				if (!found) return [...state, action.payload];
				else return newSchools;
			}
		default:
			return state;
	}
};

const initialState = null;
const api = new APIService();
export const SchoolProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
		reducer,
		initialState
	);

	React.useEffect(() => {
		getSchools();
	}, []);

	const getSchools = React.useCallback(() => {
		api.getSchools().then((data) =>
			dispatch({
				type: ActionType.SET_SCHOOLS,
				payload: data,
			})
		);
	}, []);

	const saveSchool = React.useCallback(
		(newSchool: SchoolDTO) =>
			api.saveSchool(newSchool).then((school) => {
				dispatch({
					type: ActionType.SAVE_SCHOOL,
					payload: school,
				});
				return school;
			}),
		[]
	);

	return (
		<SchoolContext.Provider value={state}>
			<SchoolUpdateContext.Provider value={{ saveSchool }}>
				{children}
			</SchoolUpdateContext.Provider>
		</SchoolContext.Provider>
	);
};

export const useSchools = () => {
	const value = React.useContext(SchoolContext);

	if (value === undefined) {
		throw new Error('SchoolContext must be used within a SchoolProvider');
	}

	return value;
};

export const useUpdateSchools = () => {
	const value = React.useContext(SchoolUpdateContext);

	if (!value) {
		throw new Error(
			'SchoolUpdateContext must be used within a SchoolUpdateProvider'
		);
	}

	return value;
};
