import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAuth } from '../contexts';

const ProtectedRoute = (props: RouteProps & { isAdmin?: boolean }) => {
	const { authenticated, user } = useAuth();
	if (authenticated === undefined) return <p>Loading...</p>;
	if (!authenticated || (props.isAdmin && !user?.isAdmin)) {
		const renderComponent = () => <Redirect to={{ pathname: '/' }} />;
		return <Route {...props} component={renderComponent} />;
	} else {
		return <Route {...props} />;
	}
};

export default ProtectedRoute;
