import { BaseDTO } from './BaseDTO';

export interface IUserDTO extends BaseDTO {
	id: number;
	name: string;
	email: string;
	isAdmin: boolean;
}

export class UserDTO extends BaseDTO implements IUserDTO {
	id: number;
	name: string;
	email: string;
	isAdmin: boolean;

	constructor(id?: number) {
		super();
		this.id = id || 0;
		this.name = '';
		this.email = '';
	}
}
