import {
	Accordion,
	AccordionSummary,
	makeStyles,
	Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import React from 'react';

const useStyles = makeStyles({
	button: {
		margin: '12px 6px 18px 0',
	},
	field: {
		marginTop: '16px',
		marginRight: '12px',
		display: 'block',
	},
	header: {
		marginTop: '10px',
		marginBottom: '10px',
		textAlign: 'center',
	},
	headerSmall: {
		marginBottom: '5px',
		marginTop: '5px',
	},
});
const SideMenuHelp = () => {
	const classes = useStyles();

	return (
		<div style={{ padding: 10 }}>
			<Typography className={classes.header} variant="h4">
				Instructions
			</Typography>
			<Accordion style={{ padding: 10 }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography variant="h6">Fields</Typography>
				</AccordionSummary>
				<Typography className={classes.headerSmall} variant="h6">
					Track
				</Typography>
				<Typography>
					The specialization track you are currently enrolled in. If
					you cannot find your track, please contact admin.
				</Typography>
				<Typography className={classes.headerSmall} variant="h6">
					Year of departure
				</Typography>
				<Typography>
					The year you depart to the exchange university. If you apply
					in 2021 but your semester starts in 2022, then 2022 is the
					year of departure.
				</Typography>
				<Typography className={classes.headerSmall} variant="h6">
					University
				</Typography>
				<Typography>
					The exchange university. Make sure you select the correct
					"country - university" combination, there are some
					universities with the same name but in different countries.
					Once you have selected an university, you must delete all
					application rows in order to change university. If you
					cannot find your university, please contact admin before
					proceeding.
				</Typography>
			</Accordion>
			<Accordion style={{ padding: 10 }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography variant="h6">Rows</Typography>
				</AccordionSummary>
				<Typography className={classes.headerSmall} variant="h6">
					What is a row?
				</Typography>
				<Typography>
					A row typically consists of one course at KTH you wish to
					replace with a course at your exchange university. In some
					cases when one or many courses are exchanged for multiple
					courses at the exchange university, multiple courses can be
					added. The row should also consists of{' '}
					<strong>comprehensive</strong> description for each of the
					exchange courses, provided in the course description field.
				</Typography>
				<Typography className={classes.headerSmall} variant="h6">
					What is required for a row to be valid?
				</Typography>
				<Typography>
					The row should consist of descriptions for each exchange
					course. The descriptions are most commonly found on the
					official website of the exchange university. If the
					description is available online, you must provide a link to
					PDF/site in which the information was optained. Failing to
					provide sufficient information about the course will lead to
					a rejection of the application. If you wish to provide
					additional information about the row, please do so in
					additional comments field.
				</Typography>
				<Typography className={classes.headerSmall} variant="h6">
					I cannot find a course
				</Typography>
				<Typography>
					If you cannot find a specific course, you have the
					possibility to create one using the buttons above the rows.
					Make sure you submit correct information about the course.
				</Typography>
				<Typography className={classes.headerSmall} variant="h6">
					I want to take an optional course at the exchange university
				</Typography>
				<Typography>
					If you want to enroll in a course without exchanging it for
					any particular KTH course, you can pick the KTH course
					"Optional". If you want to take several optional courses,
					please add one application row for each course.
				</Typography>
			</Accordion>
			<Accordion style={{ padding: 10 }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography variant="h6">Before submitting</Typography>
				</AccordionSummary>
				<Typography className={classes.headerSmall} variant="h6">
					Make sure
				</Typography>
				<Typography>
					<ol>
						<li>
							Each row consists of courses from the exchange
							university and KTH
						</li>
						<li>
							Each course has a comprehensive description and link
							to the official documentation (if available)
						</li>
						<li>
							There is no information missing which is required
							for the application to be reviewed
						</li>
						<li>You have saved your application</li>
					</ol>
					Feel free to save the application by using the PRINT button.
				</Typography>
			</Accordion>
			<Accordion style={{ padding: 10 }}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography variant="h6">
						When you have submitted
					</Typography>
				</AccordionSummary>
				<Typography>
					<ul>
						<li>
							You have the ability to withdraw the application by
							using the unsubmit button. Once the application is
							submitted the status will be updated to{' '}
							<strong>Pending approval</strong>
						</li>
						<li>
							Once the application has been reviewed it will
							receive either of the statuses
							<ul>
								<li>
									<strong>Not submitted:</strong> the
									application has been returned to you. Open
									the application and review each row to find
									the reason.
								</li>
								<li>
									<strong>Accepted:</strong> the application
									has been accepted.
								</li>
							</ul>
						</li>
					</ul>
				</Typography>
			</Accordion>
		</div>
	);
};

export default SideMenuHelp;
