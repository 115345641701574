import { VerdictStatus } from '../util/enumerations';
import { IBaseDTO, BaseDTO } from './BaseDTO';
import { CourseDTO } from './CourseDTO';
import { VerdictDTO } from './VerdictDTO';

export interface IApplicationRowDTO extends IBaseDTO {
	id: number;
	applicationHeadId: number;
	courseMappingHeadId?: number;
	comment: string;
	externalUrl: string;
	status: VerdictStatus;
	coursesExchange: CourseDTO[];
	coursesHome: CourseDTO[];
	activeVerdict: VerdictDTO; //current verdict
	verdicts: VerdictDTO[]; //verdicts of the application
	mappingHistory: VerdictDTO[]; //verdicts that are linked to other applicationrows
}

export class ApplicationRowDTO extends BaseDTO implements IApplicationRowDTO {
	id: number;
	applicationHeadId: number;
	courseMappingHeadId?: number;
	comment: string;
	externalUrl: string;
	status: VerdictStatus;
	coursesExchange: CourseDTO[];
	coursesHome: CourseDTO[];
	activeVerdict: VerdictDTO;
	verdicts: VerdictDTO[];
	mappingHistory: VerdictDTO[];

	constructor(id?: number) {
		super();
		if (id) this.id = id;
		this.applicationHeadId = 0;
		this.courseMappingHeadId = 0;
		this.comment = '';
		this.externalUrl = '';
		this.status = 0;
		this.coursesExchange = [new CourseDTO()];
		this.coursesHome = [new CourseDTO()];
	}
}
