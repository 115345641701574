import React, { useEffect, useState } from 'react';

import { APIService } from '../../../services/apiService';

import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useAuth, useSchools } from '../../../contexts';
import { MasterTrackDTO } from '../../../DTO/MasterTrackDTO';
import { useApplication, useUpdateApplication } from '..';
import { ApplicationStatus } from '../../../util/enumerations';
import { HOME_SCHOOL_ID } from '../../../constants';

const useStyles = makeStyles({
	button: {
		margin: '12px 6px 18px 0',
	},
	field: {
		marginTop: '16px',
		marginRight: '12px',
		display: 'block',
	},
});

const api = new APIService();

export const ApplicationHeadFields = () => {
	const classes = useStyles();
	const schools = useSchools();
	const { user } = useAuth();
	const { application, privileges } = useApplication();
	const { setMasterProgram, setYear, handleSelectSchool } =
		useUpdateApplication();

	const [masterTracks, setMasterTracks] = useState<MasterTrackDTO[]>([]);

	const getMasterTracks = () => {
		if (masterTracks.length == 0)
			api.getMasterTracks().then((data) => setMasterTracks(data));
	};

	useEffect(() => {
		getMasterTracks();
	}, [privileges.editCourses]);

	return React.useMemo(
		() => (
			<form>
				<FormGroup row>
					{user?.isAdmin ? (
						<>
							<TextField
								type="text"
								value={application.user?.name}
								className={classes.field}
								label="Student"
								variant="outlined"
							/>
							<TextField
								type="text"
								value={application.user?.email}
								className={classes.field}
								inputProps={{ readOnly: true }}
								label="Email"
								variant="outlined"
							/>
						</>
					) : null}
				</FormGroup>
				<FormGroup row>
					<FormControl variant="outlined" className={classes.field}>
						<InputLabel id="university-select-label">
							Track
						</InputLabel>
						<Select
							variant="outlined"
							label="Master Track"
							value={application.masterTrackId}
							onChange={(e) =>
								setMasterProgram(e.target.value as number)
							}
							inputProps={{
								readOnly: !privileges.editCourses,
							}}
							displayEmpty
							style={{ minWidth: '200px' }}
						>
							{masterTracks.map((s) => {
								return (
									<MenuItem value={s.id} key={s.id}>
										{s.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<TextField
						type="number"
						className={classes.field}
						value={application.year || ''}
						inputProps={{
							readOnly: !privileges.editCourses,
						}}
						label="Year of departure"
						variant="outlined"
						onChange={(e) => setYear(Number(e.target.value))}
					/>
					<Autocomplete
						getOptionLabel={(s) =>
							s.id
								? `${
										s.country?.name
											? s.country.name + ' -'
											: ''
								  } ${s.name}`
								: ''
						}
						value={application.school}
						style={{ minWidth: '210px' }}
						onChange={(e, school) =>
							school && handleSelectSchool(school)
						}
						getOptionSelected={(option, value) =>
							option.id === value.id
						}
						disabled={application.status !== ApplicationStatus.New}
						options={
							schools?.filter((s) => s.id != HOME_SCHOOL_ID) || []
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label="University"
								margin="normal"
								variant="outlined"
								helperText={
									application.school
										? ''
										: 'If you cannot find your university, please inform admin before proceeding'
								}
							/>
						)}
					/>
				</FormGroup>
			</form>
		),
		[
			user?.isAdmin,
			application.user,
			application.masterTrackId,
			setMasterProgram,
			privileges.editCourses,
			masterTracks,
			application.year,
			setYear,
			application.school,
			handleSelectSchool,
			application.status,
			schools,
		]
	);
};
