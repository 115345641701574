import React, { useState } from 'react';
import {
	Button,
	FormControl,
	FormGroup,
	InputLabel,
	makeStyles,
	MenuItem,
	TextField,
} from '@material-ui/core';

import { Container } from '../_atoms';
import { HOME_SCHOOL_ID } from '../../constants';
import { CourseDTO } from '../../DTO/CourseDTO';
import { States, StudyLevels } from '../../util/enumerations';
import LastEdited from '../lastEdited';
import Select from '@material-ui/core/Select';
import { useAuth, useSchools, useUpdateCourses } from '../../contexts';
import { useIsDirty } from '../../hooks/isDirty';

const useStyles = makeStyles((theme) => ({
	field: {
		marginBottom: '12px',
		marginRight: '12px',
	},
	button: {
		margin: '12px 6px 18px 0',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

type CourseProps = {
	schoolId?: number;
	initialCourse?: CourseDTO;
	onCreateCourse?: (newCourse: CourseDTO) => void;
};

const Course = ({ schoolId, initialCourse, onCreateCourse }: CourseProps) => {
	const [course, setCourse] = useState(
		initialCourse || (schoolId ? new CourseDTO(schoolId) : new CourseDTO())
	);
	const { saveCourse } = useUpdateCourses();
	const { user } = useAuth();
	const isAdmin = user?.isAdmin;
	const schools = useSchools();
	const isHome = course?.schoolId == HOME_SCHOOL_ID;
	const classes = useStyles();
	const [error, setError] = useState('');
	const [isDirty, updateCleanValue] = useIsDirty(course);

	const updateField = (field: keyof CourseDTO, value: any) => {
		setCourse((prevCourse) => ({ ...prevCourse, [field]: value }));
	};

	const setECTSCredits = (val: number) => {
		setCourse(
			(prevCourse) =>
				({
					...prevCourse,
					ectsCredits: val,
					...(isHome && { localCredits: val }),
				} as CourseDTO)
		);
	};
	const setSchool = (schoolId: number) => {
		let school = schools?.find((s) => s.id === schoolId);
		setCourse(
			(prevCourse) => ({ ...prevCourse, school, schoolId } as CourseDTO)
		);
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (!course) return;
		saveCourse(course)
			.then((newCourse) => {
				setCourse(newCourse);
				updateCleanValue(newCourse);
				setError('');
				onCreateCourse?.(newCourse);
			})
			.catch((error) => {
				setError('Error: ' + error?.response?.data?.message);
			});
	};

	if (!schools) return <p>Loading schools...</p>;
	return !course ? (
		<h1>Could not find course</h1>
	) : (
		<Container>
			<form onSubmit={handleSubmit}>
				<FormGroup row>
					<TextField
						type="text"
						value={course.code}
						label="Code"
						variant="outlined"
						className={classes.field}
						onChange={(e) => updateField('code', e.target.value)}
					/>
					<TextField
						type="text"
						value={course.title}
						label="Title"
						variant="outlined"
						className={classes.field}
						onChange={(e) => updateField('title', e.target.value)}
					/>
				</FormGroup>
				<FormGroup row>
					<TextField
						type="number"
						value={course.ectsCredits}
						label="ECTS credits"
						variant="outlined"
						className={classes.field}
						onChange={(e) => setECTSCredits(Number(e.target.value))}
					/>
					{!isHome ? (
						<TextField
							type="number"
							value={course.localCredits}
							label="Host university credits"
							variant="outlined"
							className={classes.field}
							onChange={(e) =>
								updateField(
									'localCredits',
									Number(e.target.value)
								)
							}
						/>
					) : null}
				</FormGroup>
				<FormGroup row>
					{!schools || !isAdmin ? null : (
						<FormControl
							variant="outlined"
							className={classes.formControl}
						>
							<InputLabel id="university-select-label">
								University
							</InputLabel>
							<Select
								value={course.schoolId}
								onChange={(e) =>
									setSchool(e.target.value as number)
								}
								displayEmpty
								labelId="university-select-label"
								label="University"
								className={classes.field}
							>
								<MenuItem disabled value={0}>
									<em>None</em>
								</MenuItem>
								{schools.map((s) => (
									<MenuItem value={s.id} key={s.id}>
										{s.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<FormControl variant="outlined" className={classes.field}>
						<InputLabel id="level-select-label">
							Study level
						</InputLabel>
						<Select
							value={course.level}
							onChange={(e) =>
								setCourse?.(
									(prevCourse) =>
										({
											...prevCourse,
											level: Number(e.target.value),
										} as CourseDTO)
								)
							}
							displayEmpty
							className={classes.field}
							label="Study level"
							labelId="level-select-label"
						>
							{StudyLevels.map((s) => {
								return (
									<MenuItem value={s.id} key={s.id}>
										{s.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					{isAdmin ? (
						<>
							<FormControl
								variant="outlined"
								className={classes.formControl}
							>
								<InputLabel id="status-select-label">
									Status
								</InputLabel>
								<Select
									value={course.state}
									onChange={(e) =>
										setCourse?.(
											(prevCourse) =>
												({
													...prevCourse,
													state: Number(
														e.target.value
													),
												} as CourseDTO)
										)
									}
									displayEmpty
									className={classes.field}
									label="Status"
									labelId="status-select-label"
								>
									{States.map((s) => (
										<MenuItem value={s.id} key={s.id}>
											{s.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</>
					) : null}
				</FormGroup>
				{user?.isAdmin && <LastEdited data={course} />}
				<Button
					variant="contained"
					color="primary"
					size="small"
					className={classes.button}
					disabled={!isDirty}
					type="submit"
					value="save"
				>
					Save
				</Button>
				<p>{error}</p>
			</form>
		</Container>
	);
};

export default Course;
