import { ApplicationHeadDTO } from '../../DTO/ApplicationHeadDTO';
import { InformationRowDTO } from '../../DTO/InformationRowDTO';
import {
	ApplicationStatus,
	EntityState,
	VerdictStatus,
} from '../../util/enumerations';

export const validate = (application: ApplicationHeadDTO): string => {
	let message = '';
	if (!application.schoolId || application.schoolId <= 1) {
		message += 'No university is set';
	}
	if (!application.year || application.year == 0) {
		message += 'No year of departure is set';
	}
	application.applicationRows.forEach((r, rIdx) => {
		if (!r.coursesHome || r.coursesHome.length == 0) {
			message += `Row #${rIdx + 1} is missing home university courses \n`;
		}
		if (!r.coursesExchange || r.coursesExchange.length == 0) {
			message += `Row #${
				rIdx + 1
			} is missing exchange university courses \n`;
		}
		r.coursesExchange.forEach((c) => {
			if (!c.informationRow?.note && c.state === EntityState.Active) {
				message += `Row #${
					rIdx + 1
				} have information rows missing description \n`;
			}
		});
	});
	return message;
};

export const stepStatus = (status: ApplicationStatus, step: number) => {
	if (step === 1) {
		if (
			status === ApplicationStatus.New ||
			status === ApplicationStatus.IsBeingEdited
		)
			return { active: true, completed: false, disabled: true };
		else return { active: false, completed: true };
	} else if (step === 2) {
		if (status === ApplicationStatus.PendingApproval)
			return { active: true, completed: false };
		else if (status > ApplicationStatus.PendingApproval)
			return { active: false, completed: true };
		else return { active: false, completed: false };
	} else if (step === 3) {
		if (status === ApplicationStatus.Accepted)
			return { active: false, completed: true };
		else if (status === ApplicationStatus.Closed)
			return { active: false, completed: true };
		else if (status === ApplicationStatus.Rejected)
			return { active: false, completed: true };
		else return { active: false, completed: false };
	}
};

export const applicationSums = (application: ApplicationHeadDTO) => {
	let sums = {
		rows: 0,
		rowsUnhandled: 0,
		rowsAccepted: 0,
		rowsRejected: 0,
		rowsCondAccepted: 0,
		totalECTSHome: 0,
		totalLocalHome: 0,
		totalECTSExchange: 0,
		totalLocalExchange: 0,
	};
	application.applicationRows.forEach((r) => {
		sums.rows++;
		if (r.activeVerdict) {
			switch (r.activeVerdict.status) {
				case VerdictStatus.Accepted:
					sums.rowsAccepted++;
					break;
				case VerdictStatus.AcceptedUnderSpecialCircumstances:
					sums.rowsCondAccepted++;
					break;
				case VerdictStatus.Rejected:
					sums.rowsRejected++;
					break;
				default:
					sums.rowsUnhandled++;
			}
		} else sums.rowsUnhandled++;

		r.coursesExchange.forEach((c) => {
			sums.totalLocalExchange += c.localCredits || 0;
			sums.totalECTSExchange += c.ectsCredits || 0;
		});

		r.coursesHome.forEach((c) => {
			sums.totalLocalHome += c.localCredits || 0;
			sums.totalECTSHome += c.ectsCredits || 0;
		});
	});

	return sums;
};

export const setVerdictBorderStyle = (status: VerdictStatus) => {
	switch (status) {
		case VerdictStatus.Accepted:
			return '2px solid green';
		case VerdictStatus.AcceptedUnderSpecialCircumstances:
			return '2px solid orange';
		case VerdictStatus.Rejected:
			return '2px solid red';
		default:
			return '2px solid #cccccc';
	}
};
