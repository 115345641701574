import React from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { useApplication } from '../';
import { applicationSums } from '../util';

export const ApplicationSummary = () => {
	const { application } = useApplication();
	const sums = applicationSums(application);

	return React.useMemo(
		() => (
			<Accordion
				style={{ margin: '20px' }}
				defaultExpanded={application.status > 1}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography variant="h5">Application summary</Typography>
				</AccordionSummary>
				<Grid container spacing={3} style={{ margin: '20px' }}>
					<Grid item xs={4}>
						<FormGroup row>
							<Typography variant="h6">KTH</Typography>
						</FormGroup>
						<FormGroup row>
							ECTS credits:{' '}
							{sums.totalECTSHome || sums.totalLocalHome}
						</FormGroup>
					</Grid>
					<Grid item xs={4}>
						<FormGroup row>
							<Typography variant="h6">
								{application.school?.name || ''}
							</Typography>
						</FormGroup>
						<FormGroup row>
							Local credits: {sums.totalLocalExchange}
						</FormGroup>
						<FormGroup row>
							ECTS credits: {sums.totalECTSExchange}
						</FormGroup>
					</Grid>
					<Grid item xs={4}>
						<FormGroup row>
							<Typography variant="h6">Rows</Typography>
						</FormGroup>
						<FormGroup row>Total: {sums.rows}</FormGroup>
						<FormGroup row>
							<Typography>Status:&nbsp;</Typography>
							<Tooltip title="Unhandled">
								<Typography>
									{sums.rowsUnhandled || 0}
								</Typography>
							</Tooltip>
							<Typography>&nbsp;/&nbsp;</Typography>
							<Tooltip title="Accepted">
								<Typography style={{ color: 'green' }}>
									{sums.rowsAccepted || 0}
								</Typography>
							</Tooltip>
							<Typography>&nbsp;/&nbsp;</Typography>
							<Tooltip title="Conditionally accepted">
								<Typography style={{ color: 'orange' }}>
									{sums.rowsCondAccepted || 0}
								</Typography>
							</Tooltip>
							<Typography>&nbsp;/&nbsp;</Typography>
							<Tooltip title="Rejected">
								<Typography style={{ color: 'red' }}>
									{sums.rowsRejected || 0}
								</Typography>
							</Tooltip>
						</FormGroup>
					</Grid>
				</Grid>
			</Accordion>
		),
		[sums, application.status]
	);
};
