import { ApplicationStatus } from '../util/enumerations';
import { IBaseDTO, BaseDTO } from './BaseDTO';
import { ApplicationRowDTO } from './ApplicationRowDTO';
import { DocumentDTO } from './DocumentDTO';
import { SchoolDTO } from './SchoolDTO';
import { UserDTO } from './UserDTO';
import { MasterTrackDTO } from './MasterTrackDTO';
import { InformationRowDTO } from './InformationRowDTO';

export interface IApplicationHeadDTO extends IBaseDTO {
	id: number;
	userId: number;
	schoolId?: number;
	year?: number;
	status: ApplicationStatus;
	school?: SchoolDTO;
	masterTrackId?: number;
	masterTrack?: MasterTrackDTO;
	applicationRows: ApplicationRowDTO[];
	documents?: DocumentDTO[];
}

export class ApplicationHeadDTO extends BaseDTO implements IApplicationHeadDTO {
	id: number;
	userId: number;
	schoolId?: number;
	year?: number;
	status: ApplicationStatus;
	school?: SchoolDTO;
	masterTrackId?: number;
	masterTrack?: MasterTrackDTO;
	applicationRows: ApplicationRowDTO[];
	documents?: DocumentDTO[];
	user?: UserDTO;

	constructor(user?: UserDTO) {
		super();
		this.id = 0;
		this.userId = user?.id || 0;
		this.schoolId = 0;
		this.masterTrackId = 0;
		this.year = new Date().getFullYear();
		this.status = 0;
		this.applicationRows = [];
		this.user = user || undefined;
	}
}
