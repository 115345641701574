import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Accordion } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommentIcon from '@material-ui/icons/Comment';
import LinkIcon from '@material-ui/icons/Link';

import { FormGroup, Grid, TextField, Typography } from '@material-ui/core';
import { ApplicationRowDTO } from '../../../DTO/ApplicationRowDTO';
import { CourseDTO } from '../../../DTO/CourseDTO';
import { SchoolDTO } from '../../../DTO/SchoolDTO';
import Column from '../column';
import Verdict from '../verdict/verdict';
import LastEdited from '../../../pages/lastEdited';
import InformationRows from './informationRow';
import { CourseOrigin, useApplication, useUpdateApplication } from '..';
import { useCourses } from '../../../contexts';
import { HOME_SCHOOL_ID } from '../../../constants';

const useStyles = makeStyles({
	accordion: {
		border: '1px solid #999',
		backgroundColor: '#fafaff',
	},
});

type RowFooterProps = {
	created: Date;
	createdBy: string;
	modified: Date;
	modifiedBy: string;
	comment: string;
	externalUrl: string;
	rowIndex: number;
};

const RowFooter = ({
	comment,
	externalUrl,
	rowIndex,
	created,
	createdBy,
	modified,
	modifiedBy,
}: RowFooterProps) => {
	const classes = useStyles();
	const { privileges } = useApplication();
	const { saveComment } = useUpdateApplication();
	return React.useMemo(
		() => (
			<Accordion defaultExpanded={false} className={classes.accordion}>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Grid container spacing={0}>
						<Grid item xs={10}>
							<Typography>Additional comments</Typography>
						</Grid>
						<Grid item xs={2} style={{ textAlign: 'right' }}>
							{comment ? (
								<CommentIcon
									style={{ textAlign: 'right' }}
									fontSize="small"
								/>
							) : null}
							{externalUrl ? <LinkIcon fontSize="small" /> : null}
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<div style={{ width: '100%' }}>
						<FormGroup row>
							<TextField
								id="outlined-multiline-static"
								label="Additional information"
								multiline
								fullWidth
								rows={4}
								inputProps={{
									readOnly: !privileges.editRows,
								}}
								placeholder="Additional information about the course mapping..."
								variant="outlined"
								value={comment}
								onChange={(e) =>
									saveComment(rowIndex, e.target.value)
								}
							/>
						</FormGroup>
						<FormGroup row>
							<LastEdited
								data={{
									created,
									createdBy,
									modified,
									modifiedBy,
								}}
							/>
						</FormGroup>
					</div>
				</AccordionDetails>
			</Accordion>
		),
		[
			privileges.editRows,
			comment,
			saveComment,
			rowIndex,
			created,
			createdBy,
			modified,
			modifiedBy,
		]
	);
};

interface ApplicationRowProps {
	row: ApplicationRowDTO;
	schools: { home: SchoolDTO | null; exchange: SchoolDTO | null };
	rowIndex: number;
	overridePrivileges?: Privileges;
}

const ApplicationRow = ({
	row,
	schools,
	rowIndex,
	overridePrivileges,
}: ApplicationRowProps) => {
	const courses = useCourses();
	const application = useApplication();
	const privileges = overridePrivileges || application.privileges;
	const { addCourse, removeCourse, updateCourse } = useUpdateApplication();

	const {
		id,
		created,
		createdBy,
		modified,
		modifiedBy,
		comment,
		externalUrl,
		coursesHome,
		coursesExchange,
		activeVerdict,
		courseMappingHeadId,
	} = row;

	return React.useMemo(
		() => (
			<>
				<Grid container spacing={3}>
					<Grid
						item
						xs={6}
						style={{
							marginTop: '10px',
							borderRight: '1px solid #cccccc',
						}}
					>
						<Typography
							gutterBottom
							variant="h5"
							component="h2"
							align="center"
						>
							{schools.home?.name}
							<Typography>
								&nbsp;{schools.home?.country?.name}&nbsp;
							</Typography>
						</Typography>
						<Column
							courses={coursesHome}
							allCourses={courses?.filter(
								(c) => c.schoolId === HOME_SCHOOL_ID
							)}
							isHome={true}
							handlers={{
								addCourse: addCourse(
									rowIndex,
									CourseOrigin.HOME
								),
								removeCourse: removeCourse(
									rowIndex,
									CourseOrigin.HOME
								),
								updateCourse: updateCourse(
									rowIndex,
									CourseOrigin.HOME
								),
							}}
						/>
					</Grid>
					<Grid item xs={6} style={{ marginTop: '10px' }}>
						<Typography
							gutterBottom
							variant="h5"
							component="h2"
							align="center"
						>
							{schools.exchange?.name || 'Choose university'}
							<Typography>
								&nbsp;{schools.exchange?.country?.name}&nbsp;
							</Typography>
						</Typography>
						<Column
							courses={coursesExchange}
							allCourses={courses?.filter(
								(c) =>
									c.schoolId ===
									application.application.school?.id
							)}
							isHome={false}
							handlers={{
								addCourse: addCourse(
									rowIndex,
									CourseOrigin.EXCHANGE
								),
								removeCourse: removeCourse(
									rowIndex,
									CourseOrigin.EXCHANGE
								),
								updateCourse: updateCourse(
									rowIndex,
									CourseOrigin.EXCHANGE
								),
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						{...(!!row.coursesExchange.filter((c) => c.id)
							.length && {
							style: { marginTop: '32px' },
						})}
					>
						<InformationRows
							coursesExchange={coursesExchange}
							rowIndex={rowIndex}
						/>
					</Grid>
					<Grid item xs={12}>
						<RowFooter
							created={created}
							createdBy={createdBy}
							modified={modified}
							modifiedBy={modifiedBy}
							comment={comment}
							externalUrl={externalUrl}
							rowIndex={rowIndex}
						/>
					</Grid>
				</Grid>
				<br />
				<Verdict
					rowId={id || 0}
					courseMappingHeadId={courseMappingHeadId || 0}
					activeVerdict={activeVerdict}
					rowIndex={rowIndex}
				/>
			</>
		),
		[
			schools.home,
			schools.exchange,
			courses,
			row,
			addCourse,
			removeCourse,
			updateCourse,
			rowIndex,
			privileges.editRows,
			activeVerdict,
		]
	);
};

export default ApplicationRow;
