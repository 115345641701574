import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ColDef, DataGrid } from '@material-ui/data-grid';

import {
	Button,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { appVerdictName, VerdictStatus } from '../../../util/enumerations';
import { VerdictDTO } from '../../../DTO/VerdictDTO';
import { useUpdateApplication } from '..';
import { APIService } from '../../../services/apiService';
import { ROUTES } from '../../../constants';
import { setVerdictBorderStyle } from '../util';

type CreateVerdictProps = {
	verdict: VerdictDTO | null;
	setVerdict: React.Dispatch<React.SetStateAction<VerdictDTO | null>>;
	rowIndex: number;
};
const api = new APIService();

export const CreateVerdict = React.memo(
	({ verdict, setVerdict, rowIndex }: CreateVerdictProps) => {
		const [showHistory, setShowHistory] = useState(false);
		const [mappingHistoryLocal, setMappingHistoryLocal] = useState<
			VerdictDTO[] | null
		>(null);
		const { giveVerdict } = useUpdateApplication();
		const verdicts = mappingHistoryLocal || [];
		const getMappingHistory = React.useCallback(() => {
			if (!verdict || !verdict.courseMappingHeadId) return;

			if (verdicts.length === 0 || showHistory === true) {
				api.getMappingHistory(verdict?.courseMappingHeadId).then(
					(data) => {
						setMappingHistoryLocal(data);
					}
				);
			}
			setShowHistory(true);
		}, [verdict, showHistory]);

		useEffect(() => {
			getMappingHistory();
		}, []);

		const copyVerdict = React.useCallback(
			(copiedVerdict: VerdictDTO) => {
				if (!verdict) return;
				setVerdict(
					(prevVerdict) =>
						({
							...prevVerdict,
							note: copiedVerdict.note,
							status: copiedVerdict.status,
						} as VerdictDTO)
				);
			},
			[verdict]
		);

		const saveVerdict = React.useCallback(() => {
			if (!verdict) return;
			api.saveVerdict(verdict)
				.then((data) => {
					setVerdict(null);
					giveVerdict(rowIndex, data);
				})
				.catch((ex) => console.log(ex));
		}, [verdict]);

		const colDefs: ColDef[] = React.useMemo(
			() => [
				{
					field: 'status',
					headerName: 'Status',
					width: 300,
					valueGetter: (params) =>
						appVerdictName(params.value as VerdictStatus),
				},
				{
					field: 'note',
					headerName: 'Note',
					width: 300,
					renderCell: (params) => (
						<Tooltip title={params.value as string}>
							<Typography style={{ fontSize: '14px' }}>
								{(params.value as string)?.substring(0, 60) +
									'...'}
							</Typography>
						</Tooltip>
					),
				},
				{
					field: 'copy',
					headerName: ' ',
					width: 100,
					renderCell: (params) => (
						<Button
							variant="contained"
							color="secondary"
							onClick={() =>
								copyVerdict(params.data as VerdictDTO)
							}
						>
							Copy
						</Button>
					),
				},
				{
					field: 'edit',
					headerName: ' ',
					width: 180,
					renderCell: (params) => (
						<Button
							variant="contained"
							color="primary"
							component={Link}
							to={`${ROUTES.APPLICATION}/${params.data.applicationHeadId}`}
						>
							Open application
						</Button>
					),
				},
			],
			[copyVerdict]
		);

		return React.useMemo(
			() =>
				verdict ? (
					<div>
						<div
							style={{
								border: setVerdictBorderStyle(verdict.status),
								padding: '10px',
							}}
						>
							<strong>Verdict</strong>
							<br />
							<FormLabel
								style={{ marginTop: '10px' }}
								component="legend"
							>
								Status
							</FormLabel>
							<RadioGroup
								aria-label="verdictStatus"
								name="status"
								value={verdict?.status}
								onChange={(e) =>
									verdict &&
									setVerdict({
										...verdict,
										status: Number(e.target.value),
									})
								}
							>
								<FormControlLabel
									value="1"
									control={<Radio />}
									label={appVerdictName(
										VerdictStatus.Accepted
									)}
									checked={
										verdict.status ===
										VerdictStatus.Accepted
									}
								/>
								<FormControlLabel
									value="2"
									control={<Radio />}
									label={appVerdictName(
										VerdictStatus.AcceptedUnderSpecialCircumstances
									)}
									checked={
										verdict?.status ===
										VerdictStatus.AcceptedUnderSpecialCircumstances
									}
								/>
								<FormControlLabel
									value="3"
									control={<Radio />}
									label={appVerdictName(
										VerdictStatus.Rejected
									)}
									checked={
										verdict.status ===
										VerdictStatus.Rejected
									}
								/>
							</RadioGroup>
							<br />
							<TextField
								id="outlined-multiline-static"
								style={{ width: '100%' }}
								label="Note"
								multiline
								rows={4}
								placeholder="Note..."
								variant="outlined"
								value={verdict.note}
								onChange={(e) =>
									setVerdict({
										...verdict,
										note: e.target.value,
									})
								}
							/>
							<br />
							<Button
								variant="contained"
								color="primary"
								style={{ marginTop: '10px' }}
								size="small"
								disabled={verdict.status < 1}
								onClick={() => saveVerdict()}
							>
								Save
							</Button>
							<Button
								variant="contained"
								color="secondary"
								style={{
									marginLeft: '10px',
									marginTop: '10px',
								}}
								size="small"
								onClick={() => getMappingHistory()}
							>
								Show history
							</Button>
							{showHistory ? (
								<Button
									variant="contained"
									color="secondary"
									style={{
										marginLeft: '10px',
										marginTop: '10px',
									}}
									size="small"
									onClick={() => setShowHistory(false)}
								>
									Hide history
								</Button>
							) : null}
						</div>
						<br />
						{showHistory ? (
							<div style={{ height: '400px' }}>
								<DataGrid
									hideFooterPagination={true}
									autoHeight={false}
									columns={colDefs}
									hideFooter={true}
									rows={verdicts}
									disableExtendRowFullWidth={true}
								/>
							</div>
						) : null}
					</div>
				) : null,
			[
				verdict,
				saveVerdict,
				getMappingHistory,
				showHistory,
				colDefs,
				verdicts,
			]
		);
	}
);
