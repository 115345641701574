import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import LastEdited from '../lastEdited';
import { useApplication, useUpdateApplication } from '.';

const useStyles = makeStyles({
	error: {
		color: '#e74c3c',
		fontWeight: 'bold',
	},
});

// Do not optimize. This depends on `application`
const ApplicationControls = () => {
	const { application, privileges, serverMessage, isDirty } =
		useApplication();
	const { saveApplication } = useUpdateApplication();
	const classes = useStyles();

	return (
		<>
			{serverMessage && <p className={classes.error}>{serverMessage}</p>}
			{privileges.editCourses &&
			application.applicationRows.length > 0 ? (
				<Button
					style={{ marginTop: '20px' }}
					onClick={() => saveApplication()}
					variant="contained"
					color="primary"
					disabled={
						(application.schoolId !== undefined &&
							application?.schoolId < 1) ||
						!isDirty
					}
				>
					Save
				</Button>
			) : null}
			<LastEdited data={application} />
		</>
	);
};

export default ApplicationControls;
