import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { GridColDef, DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, Typography } from '@material-ui/core';

import { Container } from '../components/_atoms';
import SearchComponent from '../components/Search';
import { ROUTES } from '../constants';
import { UserDTO } from '../DTO/UserDTO';
import { APIService } from '../services/apiService';

const colDefs: GridColDef[] = [
	{ field: 'id', headerName: 'ID', type: 'number', minWidth: 50 },
	{ field: 'name', headerName: 'Name', flex: 1, minWidth: 200 },
	{ field: 'email', headerName: 'Email', flex: 1, minWidth: 160 },
	{
		field: 'isAdmin',
		headerName: 'Admin',
		type: 'boolean',
		minWidth: 120,
	},
	{
		field: 'edit',
		headerName: ' ',
		width: 100,
		renderCell: (params) => (
			<Button
				variant="contained"
				color="primary"
				size="small"
				component={Link}
				to={`${ROUTES.USER}/${params.row.id}`}
			>
				Edit
			</Button>
		),
	},
];

const api = new APIService();

const Users = () => {
	const [users, setUsers] = useState<UserDTO[]>([]);

	useEffect(() => {
		api.getUsers().then((data) => {
			setUsers(data);
		});
	}, []);

	return (
		<Container>
			<Typography
				gutterBottom
				style={{ marginTop: '5px' }}
				variant="h4"
				align="center"
			>
				Users
			</Typography>
			<div
				style={{
					height: 'calc(100vh - 200px)',
					width: '100%',
					marginTop: '10px',
				}}
			>
				<DataGrid
					columns={colDefs}
					rows={users}
					components={{
						Toolbar: GridToolbar,
					}}
				/>
			</div>
		</Container>
	);
};

export default Users;
