import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import GavelIcon from '@material-ui/icons/Gavel';
import { VerdictDTO } from '../../../DTO/VerdictDTO';
import { getDateAndTime } from '../../../util/dateHelper';
import { appVerdictName } from '../../../util/enumerations';
import { useApplication } from '..';
import { CreateVerdict } from './createVerdict';
import { setVerdictBorderStyle } from '../util';

type VerdictProps = {
	activeVerdict: VerdictDTO;
	rowId: number;
	courseMappingHeadId: number;
	rowIndex: number;
};

const Verdict = ({
	rowId,
	courseMappingHeadId,
	activeVerdict,
	rowIndex,
}: VerdictProps) => {
	const { privileges } = useApplication();
	const [verdict, setVerdict] = useState<VerdictDTO | null>(null);

	const setNewVerdict = React.useCallback(() => {
		setVerdict(new VerdictDTO(rowId, courseMappingHeadId));
	}, []);

	const editActiveVerdict = React.useCallback(() => {
		setVerdict(activeVerdict);
	}, [activeVerdict]);

	const hammerOfThor = React.useMemo(
		() => (
			<div style={{ textAlign: 'center' }}>
				<IconButton
					color="primary"
					aria-label="setverdict"
					onClick={() => setNewVerdict()}
				>
					<GavelIcon fontSize="large" />
				</IconButton>
			</div>
		),
		[setNewVerdict]
	);

	const displayActiveVerdict = React.useMemo(
		() =>
			!activeVerdict ? null : (
				<div
					style={{
						border: setVerdictBorderStyle(activeVerdict.status),
						padding: '10px',
					}}
				>
					<strong>{appVerdictName(activeVerdict.status)}</strong>
					<br />
					<small>{`${getDateAndTime(
						activeVerdict.modified || activeVerdict.created
					)} by ${activeVerdict.createdBy}`}</small>
					<p>{activeVerdict.note}</p>
					{privileges.giveVerdict ? (
						<div style={{ textAlign: 'right' }}>
							<IconButton
								aria-label="edit"
								onClick={() => editActiveVerdict()}
							>
								<EditIcon fontSize="small" />
							</IconButton>
						</div>
					) : null}
				</div>
			),
		[
			activeVerdict,
			appVerdictName,
			privileges.giveVerdict,
			editActiveVerdict,
		]
	);

	if (!rowId || !courseMappingHeadId) return null;
	if (!verdict && !activeVerdict && privileges.giveVerdict)
		return hammerOfThor;

	if (!verdict && activeVerdict) return displayActiveVerdict;
	if (verdict && privileges.giveVerdict)
		return (
			<CreateVerdict
				verdict={verdict}
				setVerdict={setVerdict}
				rowIndex={rowIndex}
			/>
		);
	return null;
};

export default Verdict;
