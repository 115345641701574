import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Container } from '../components/_atoms';
import { ROUTES } from '../constants';
import SearchComponent from '../components/Search';
import schools from '../model/schools_extended.json';
import { APIService } from '../services/apiService';
import { GridColDef, DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, FormGroup, Typography } from '@material-ui/core';
import { getDateAndTime } from '../util/dateHelper';
import { MasterTrackDTO } from '../DTO/MasterTrackDTO';

const colDefs: GridColDef[] = [
	{ field: 'id', headerName: 'ID', type: 'number', flex: 0.5, minWidth: 50 },
	{ field: 'name', headerName: 'Name', flex: 1, minWidth: 200 },
	{
		field: 'created',
		headerName: 'Created',
		flex: 1,
		type: 'dateTime',
		minWidth: 200,
		valueGetter: (params) =>
			params.value ? new Date(params.value as string) : null,
	},
	{
		field: 'modified',
		headerName: 'Modified',
		flex: 1,
		type: 'dateTime',
		minWidth: 200,
		valueGetter: (params) =>
			params.value ? new Date(params.value as string) : null,
	},
	{
		field: 'edit',
		headerName: ' ',
		flex: 0.5,
		minWidth: 100,
		renderCell: (params) => (
			<Button
				variant="contained"
				color="primary"
				size="small"
				component={Link}
				to={`${ROUTES.MASTERTRACK}/${params.row.id}`}
			>
				Open
			</Button>
		),
	},
];

const api = new APIService();

const MasterTracks = () => {
	const [masterTracks, setMasterTracks] = useState<MasterTrackDTO[]>([]);

	const getMasterTracks = () => {
		api.getMasterTracks().then((data) => setMasterTracks(data));
	};

	useEffect(() => {
		getMasterTracks();
	}, []);

	if (!schools) return null;
	return (
		<Container>
			<Typography
				gutterBottom
				style={{ marginTop: '5px' }}
				variant="h4"
				align="center"
			>
				Tracks
			</Typography>
			<FormGroup row>
				<Button
					variant="contained"
					color="secondary"
					style={{ float: 'right' }}
					size="small"
					component={Link}
					to={`${ROUTES.MASTERTRACK}/${0}`}
				>
					Create new
				</Button>
			</FormGroup>
			<div
				style={{
					height: 'calc(100vh - 230px)',
					width: '100%',
					marginTop: '10px',
				}}
			>
				<DataGrid
					columns={colDefs}
					rows={masterTracks}
					components={{
						Toolbar: GridToolbar,
					}}
				/>
			</div>
		</Container>
	);
};

export default MasterTracks;
