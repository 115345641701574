import React, { useState, useEffect } from 'react';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ApplicationRow from '../components/application/row/row';
import { ApplicationRowExDTO } from '../DTO/ApplicationRowExDTO';
import { APIService } from '../services/apiService';
import { HOME_SCHOOL_ID, ROUTES } from '../constants';
import { useSchools } from '../contexts';
import { VerdictDTO } from '../DTO/VerdictDTO';
import {
	ApplicationContext,
	ApplicationUpdateContext,
} from '../components/application';
import { ApplicationState } from '../components/application/ApplicationState';
import { MockApplicationHandlers } from '../components/application/ApplicationHandlers';

const api = new APIService();
const privileges = {
	addCourses: false,
	editRows: false,
	editCourses: false,
	addComment: true,
	giveVerdict: true,
};

const sideMargin = { marginLeft: '10px', marginRight: '10px' };

const ApplicationRowsAdmin = (): JSX.Element => {
	const schools = useSchools();
	const [applicationRows, setApplicationRows] = useState<
		ApplicationRowExDTO[] | null
	>(null);

	const getRows = () => {
		api.getUnhandledApplicationRows().then((data) => {
			setApplicationRows(data);
		});
	};

	useEffect(() => {
		getRows();
	}, []);

	// TODO: Den här gick nog sönder i application refaktorering.
	const getVerdictMappingFunction = (idx: number, verdict: VerdictDTO) => {
		if (!applicationRows) return;
		applicationRows[idx].activeVerdict = verdict;
		setApplicationRows([...applicationRows]);
	};

	if (!applicationRows) return <p>Loading rows...</p>;
	return (
		<ApplicationContext.Provider
			value={new ApplicationState({ privileges })}
		>
			<ApplicationUpdateContext.Provider
				value={
					new MockApplicationHandlers({
						giveVerdict: getVerdictMappingFunction,
					})
				}
			>
				<Typography
					gutterBottom
					style={{ marginTop: '5px' }}
					variant="h4"
					align="center"
				>
					Application rows
				</Typography>
				{applicationRows.length === 0 ? (
					<p>No unhandled rows.</p>
				) : null}
				{applicationRows.map((row, idx) => (
					<Paper
						elevation={5}
						style={{
							padding: '10px',
							marginBottom: '20px',
							marginTop: '10px',
						}}
					>
						<div
							style={{
								borderBottom: '1px solid #cccccc',
								paddingBottom: '10px',
							}}
						>
							<TextField
								type="text"
								value={row.userName}
								inputProps={{ readOnly: true }}
								label="Student"
								variant="outlined"
								style={sideMargin}
							/>
							<TextField
								type="text"
								value={row.masterProgram}
								label="Master track"
								variant="outlined"
								style={sideMargin}
							/>
							<Button
								variant="contained"
								color="primary"
								size="small"
								style={{ margin: '10px' }}
								component={Link}
								to={`${ROUTES.APPLICATION}/${row.applicationHeadId}`}
							>
								Open application
							</Button>
						</div>
						<br />
						<div key={idx}>
							<ApplicationRow
								row={{ ...row, id: row.applicationRowId }}
								schools={{
									home:
										schools?.find(
											(s) => s.id === HOME_SCHOOL_ID
										) || null,
									exchange:
										schools?.find(
											(s) => s.id === row.schoolId
										) || null,
								}}
								overridePrivileges={privileges}
								rowIndex={idx}
							/>
						</div>
					</Paper>
				))}
			</ApplicationUpdateContext.Provider>
		</ApplicationContext.Provider>
	);
};

export default ApplicationRowsAdmin;
