import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Container } from '../components/_atoms';
import { ROUTES } from '../constants';
import SearchComponent from '../components/Search';
import { GridColDef, DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button, FormGroup, Typography } from '@material-ui/core';
import { getDateAndTime } from '../util/dateHelper';
import { useSchools } from '../contexts';

const colDefs: GridColDef[] = [
	{ field: 'id', headerName: 'ID', type: 'number', flex: 0.5, minWidth: 50 },
	{ field: 'name', headerName: 'Name', flex: 1, minWidth: 200 },
	{
		field: 'created',
		headerName: 'Created',
		type: 'dateTime',

		flex: 1,
		minWidth: 200,
		valueGetter: (params) =>
			params.value ? new Date(params.value as string) : null,
	},
	{
		field: 'modified',
		headerName: 'Modified',
		flex: 1,
		type: 'dateTime',
		minWidth: 200,
		valueGetter: (params) =>
			params.value ? new Date(params.value as string) : null,
	},
	{
		field: 'country',
		headerName: 'Country',
		flex: 1,
		minWidth: 200,
		valueGetter: (params) => params.row?.country?.name,
	},
	{
		field: 'edit',
		headerName: 'Open',
		flex: 1,
		minWidth: 100,
		renderCell: (params) => (
			<Button
				variant="contained"
				color="primary"
				size="small"
				component={Link}
				to={`${ROUTES.SCHOOL}/${params.row.id}`}
			>
				Open
			</Button>
		),
	},
	{
		field: 'courses',
		headerName: 'Courses',
		flex: 1,
		minWidth: 100,
		renderCell: (params) => (
			<Button
				variant="contained"
				color="primary"
				size="small"
				component={Link}
				to={`${ROUTES.COURSES}/${params.row.id}`}
			>
				Courses
			</Button>
		),
	},
];

const Schools = () => {
	const schools = useSchools();

	if (!schools) return null;
	return (
		<Container>
			<Typography
				gutterBottom
				style={{ marginTop: '5px' }}
				variant="h4"
				align="center"
			>
				Universities
			</Typography>
			<FormGroup row>
				<Button
					variant="contained"
					color="secondary"
					style={{ float: 'right' }}
					size="small"
					component={Link}
					to={`${ROUTES.SCHOOL}/${0}`}
				>
					Create new
				</Button>
			</FormGroup>
			<div
				style={{
					height: 'calc(100vh - 200px)',
					width: '100%',
					marginTop: '10px',
				}}
			>
				<DataGrid
					columns={colDefs}
					rows={schools}
					components={{
						Toolbar: GridToolbar,
					}}
				/>
			</div>
		</Container>
	);
};

export default Schools;
