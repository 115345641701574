import { BaseDTO } from './BaseDTO';

export interface InformationRowDTO extends BaseDTO {
	id: number;
	applicationRowId?: number;
	courseId?: number;
	note: string;
	externalUrl?: string;
}

export class InformationRowDTO extends BaseDTO implements InformationRowDTO {
	id: number;
	applicationRowId?: number;
	courseId?: number;
	note: string;
	externalUrl?: string;

	constructor(courseId: number) {
		super();
		this.note = '';
		this.externalUrl = '';
		this.courseId = courseId;
	}
}
