import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import Course from '../components/course';
import { ROUTES } from '../constants';
import { CourseDTO } from '../DTO/CourseDTO';
import { APIService } from '../services/apiService';

// TODO: remove this?
const api = new APIService();
const SingleCourse = () => {
	const match = useRouteMatch<{ courseId?: string }>();
	const history = useHistory();
	const [course, setCourse] = useState<CourseDTO | null>(null);
	const [error, setError] = useState('');

	const onCreateCourse = (newCourse: CourseDTO) => {
		course?.id === 0 && history.push(`${ROUTES.COURSE}/${newCourse.id}`);
	};

	useEffect(() => {
		const parsedCourseId = Number(match.params.courseId);
		if (isNaN(parsedCourseId)) {
			setError('Invalid course id');
		} else {
			if (parsedCourseId > 0) {
				api.getCourseById(parsedCourseId).then((data) => {
					setCourse(data);
				});
			} else {
				setCourse(new CourseDTO());
			}
		}
	}, [match.params.courseId]);

	const renderContent = () => {
		if (error) return <p>{error}</p>;
		if (!course) return null;
		return (
			<Course onCreateCourse={onCreateCourse} initialCourse={course} />
		);
	};

	return (
		<>
			<h1>
				{course
					? course.id
						? `${course.code} - ${course.title}`
						: 'Create course'
					: 'Loading course...'}
			</h1>
			{renderContent()}
		</>
	);
};

export default SingleCourse;
