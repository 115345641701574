import React, { useState } from 'react';

import CourseModal from '../course/courseModal';
import Button from '@material-ui/core/Button';
import { HOME_SCHOOL_ID } from '../../constants';
import { FormGroup } from '@material-ui/core';

type CreateCoursesProps = {
	schoolId: number;
	schoolName: string;
};
const CreateCourses = ({ schoolId, schoolName }: CreateCoursesProps) => {
	const [modal, setModal] = useState<{ schoolId?: number; open: boolean }>({
		open: false,
	});

	const handleClickOpenCourseModal = React.useCallback((schoolId: number) => {
		setModal({ schoolId, open: true });
	}, []);

	const onCourseModalClose = React.useCallback(() => {
		setModal({ open: false });
	}, []);

	return React.useMemo(
		() => (
			<>
				<FormGroup row style={{ marginTop: '40px' }}>
					<div style={{ width: '50%', textAlign: 'center' }}>
						<Button
							variant="contained"
							onClick={() =>
								handleClickOpenCourseModal(HOME_SCHOOL_ID)
							}
						>{`Create KTH course`}</Button>
					</div>
					<div style={{ width: '50%', textAlign: 'center' }}>
						{schoolId ? (
							<Button
								variant="contained"
								onClick={() =>
									handleClickOpenCourseModal(schoolId)
								}
							>{`Create ${schoolName} course`}</Button>
						) : null}
					</div>
				</FormGroup>
				<CourseModal
					schoolId={modal.schoolId || schoolId}
					open={modal.open}
					onClose={onCourseModalClose}
				/>
				<br />
			</>
		),
		[
			schoolName,
			schoolId,
			modal,
			handleClickOpenCourseModal,
			onCourseModalClose,
		]
	);
};

export default CreateCourses;
