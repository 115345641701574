export function getDate(date: Date): string {
	if (!date) return '';
	return date.toDateString();
}

export function getTime(date: Date): string {
	if (!date) return '';
	return date.toTimeString();
}

export const getDateAndTime = (date: Date) => {
	if (!date) return '';
	let currentDatetime = new Date(date);
	let formattedDate =
		currentDatetime.getFullYear() +
		'-' +
		appendLeadingZeroes(currentDatetime.getMonth() + 1) +
		'-' +
		appendLeadingZeroes(currentDatetime.getDate()) +
		' ' +
		appendLeadingZeroes(currentDatetime.getHours()) +
		':' +
		appendLeadingZeroes(currentDatetime.getMinutes()) +
		':' +
		appendLeadingZeroes(currentDatetime.getSeconds());
	return formattedDate;
};

function appendLeadingZeroes(n: number) {
	if (n <= 9) {
		return '0' + n.toString();
	}
	return n.toString();
}
