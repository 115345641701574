import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import Typography from '@material-ui/core/Typography';
import { APIService } from '../services/apiService';
import { ApplicationHeadDTO } from '../DTO/ApplicationHeadDTO';
import Application from '../components/application';
import { useAuth } from '../contexts';
import { ApplicationStatus } from '../util/enumerations';

const api = new APIService();

const SingleApplication = () => {
	const match = useRouteMatch<{ id?: string }>();
	const { user } = useAuth();
	const [application, setApplication] =
		React.useState<ApplicationHeadDTO | null>(null);

	const canEdit = (applicationStatus?: ApplicationStatus) =>
		!!(
			!user?.isAdmin &&
			application &&
			applicationStatus &&
			[ApplicationStatus.New, ApplicationStatus.IsBeingEdited].includes(
				applicationStatus
			)
		);

	const editMode = canEdit(application?.status);

	const [privileges, setPrivileges] = useState<Privileges>({
		addCourses: editMode,
		editRows: editMode,
		editCourses: editMode,
		giveVerdict:
			!!user?.isAdmin &&
			application?.status === ApplicationStatus.PendingApproval,
	});
	useEffect(() => {
		if (match.params.id) {
			api.getApplicationById(parseInt(match.params.id)).then((app) =>
				setApplication(app)
			);
		}
	}, [match.params.id]);

	const handleStatusUpdate = (newStatus: ApplicationStatus) => {
		const editMode = canEdit(newStatus);
		setPrivileges({
			addCourses: editMode,
			editRows: editMode,
			editCourses: editMode,
			giveVerdict:
				!!user?.isAdmin &&
				newStatus === ApplicationStatus.PendingApproval,
		});
	};

	if (!application) return <p>Retrieving study plan...</p>;

	return (
		<>
			<Typography
				gutterBottom
				style={{ marginTop: '5px' }}
				variant="h4"
				align="center"
			>
				{`${application.id ? '' : 'New '}Application`}
			</Typography>
			<Application
				onStatusUpdate={handleStatusUpdate}
				initialApplication={application}
				privileges={privileges}
			/>
		</>
	);
};

export default SingleApplication;
