import React from 'react';

import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { stepStatus } from '../util';
import { useApplication } from '..';

export const ApplicationStepper = () => {
	const { application } = useApplication();
	return React.useMemo(
		() => (
			<Stepper alternativeLabel style={{ width: '100%' }}>
				<Step {...stepStatus(application.status, 1)}>
					<StepLabel>Preparation</StepLabel>
				</Step>
				<Step {...stepStatus(application.status, 2)}>
					<StepLabel>Pending approval</StepLabel>
				</Step>
				<Step {...stepStatus(application.status, 3)}>
					<StepLabel>Application closed</StepLabel>
				</Step>
			</Stepper>
		),
		[application.status]
	);
};
