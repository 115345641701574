import React, { useState } from 'react';
import {
	Button,
	Card,
	Grid,
	IconButton,
	TextField,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import InfoIcon from '@material-ui/icons/Info';

import { CourseDTO } from '../../DTO/CourseDTO';
import { levelName } from '../../util/enumerations';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { useApplication } from '.';

const useStyles = makeStyles({
	addButton: {
		marginTop: '12px',
	},
});

type ApplicationColumnProps = {
	courses: CourseDTO[];
	allCourses: CourseDTO[] | undefined;
	isHome: boolean;
	handlers: {
		addCourse: Function;
		removeCourse: Function;
		updateCourse: Function;
	};
};

const iconStyles = {
	marginTop: '20px',
};

const ApplicationColumn = React.memo((props: ApplicationColumnProps) => {
	const classes = useStyles();
	const { privileges } = useApplication();
	const [displayCourseInfo, setDisplayCourseInfo] = useState<number[]>([]);

	const toggleShowCourseInfo = React.useCallback((courseId: number) => {
		setDisplayCourseInfo((prevDisplayCourseInfo) => {
			const newCourseInfo = prevDisplayCourseInfo.filter(
				(c) => c !== courseId
			);
			if (newCourseInfo.length < prevDisplayCourseInfo.length)
				return newCourseInfo;
			else return [...newCourseInfo, courseId];
		});
	}, []);

	const renderCourseInfo = React.useCallback(
		(course: CourseDTO) => (
			<>
				<br />
				<Card
					style={{ padding: '10px', margin: '10px', width: '100%' }}
				>
					<Typography
						gutterBottom
						variant="h6"
						component="h2"
						align="center"
					>
						{course.code}
					</Typography>
					<TextField
						type="text"
						fullWidth
						value={course.title}
						inputProps={{ readOnly: true }}
						label="Title"
						size="small"
					/>
					<TextField
						type="text"
						style={{ marginTop: '5px' }}
						fullWidth
						value={course.localCredits}
						inputProps={{ readOnly: true }}
						label="Credits"
						size="small"
					/>
					<TextField
						type="text"
						style={{ marginTop: '5px' }}
						fullWidth
						value={levelName(course.level)}
						inputProps={{ readOnly: true }}
						label="Level"
						size="small"
					/>
				</Card>
			</>
		),
		[]
	);

	const {
		courses,
		allCourses,
		handlers: { addCourse, removeCourse, updateCourse },
	} = props;

	return React.useMemo(
		() => (
			<div style={{ textAlign: 'center' }}>
				{courses.map((c, idx) => (
					<Grid container spacing={0} key={idx}>
						{privileges.editCourses ? (
							<>
								<Grid item xs={9}>
									<Autocomplete
										value={c}
										options={allCourses || []}
										getOptionLabel={(c) =>
											c.id ? `${c.code} - ${c.title}` : ''
										}
										disableClearable={true}
										{...(c.id > 0 && { value: c })}
										onChange={(_, course) => {
											updateCourse(idx, course.id, c.id);
										}}
										noOptionsText={
											'If you cannot find your course, feel free to create a new one using the button above!'
										}
										getOptionSelected={(option, value) =>
											option.id === value.id
										}
										renderInput={(params) => (
											<TextField
												variant="outlined"
												{...params}
												label="Course"
												margin="normal"
											/>
										)}
									/>
								</Grid>
							</>
						) : (
							<Grid item xs={9}>
								<TextField
									type="text"
									style={{ margin: '5px', width: '100%' }}
									value={`${c.code} - ${c.title}`}
									inputProps={{ readOnly: true }}
									label="Title"
									size="small"
								/>
							</Grid>
						)}
						{c.id ? (
							<Grid item xs={1}>
								<IconButton
									style={iconStyles}
									aria-label="info"
									onClick={() => toggleShowCourseInfo(c.id)}
								>
									<InfoIcon fontSize="small" />
								</IconButton>
							</Grid>
						) : null}
						{privileges.editCourses ? (
							<Grid item xs={1}>
								<IconButton
									style={iconStyles}
									color="secondary"
									aria-label="delete"
									onClick={() => removeCourse(idx, c.id)}
								>
									<ClearIcon fontSize="small" />
								</IconButton>
							</Grid>
						) : null}
						{displayCourseInfo.includes(c.id) &&
							renderCourseInfo(c)}
					</Grid>
				))}
				{courses.length > 1 &&
				privileges.editCourses &&
				props.isHome ? (
					<Alert severity="info" style={{ textAlign: 'left' }}>
						<AlertTitle>Info</AlertTitle>
						There is typically <strong>one</strong> course on the
						home university
					</Alert>
				) : null}
				{privileges.editCourses && (
					<Button
						color="primary"
						variant="contained"
						onClick={() => addCourse()}
						className={classes.addButton}
					>
						Add course
					</Button>
				)}
			</div>
		),
		[
			courses,
			privileges.editCourses,
			allCourses,
			updateCourse,
			toggleShowCourseInfo,
			removeCourse,
			displayCourseInfo,
			renderCourseInfo,
			props.isHome,
			addCourse,
		]
	);
});

export default ApplicationColumn;
