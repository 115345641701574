import React from 'react';
import { Link } from 'react-router-dom';

const notFound = () => {
	return (
		<div>
			<p>404 - The page could not be found</p>
			Return <Link to="/">Home</Link>
		</div>
	);
};

export default notFound;
