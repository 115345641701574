export enum EntityState {
	Active,
	Inactive,
	Removed,
}

export enum VerdictStatus {
	Unhandled,
	Accepted,
	AcceptedUnderSpecialCircumstances,
	Rejected,
}

export enum StudyLevel {
	Unknown,
	Bachelor,
	Master,
}

export enum IsAdmin {
	False,
	True,
}

export enum ApplicationStatus {
	New,
	IsBeingEdited,
	PendingApproval,
	Accepted,
	Rejected,
	Closed,
}

export const AdminLevels = [
	{ id: IsAdmin.False, isAdmin: IsAdmin.False },
	{ id: IsAdmin.True, isAdmin: IsAdmin.True },
];

export const StudyLevels = [
	{ id: StudyLevel.Unknown, name: 'Unknown' },
	{ id: StudyLevel.Bachelor, name: 'Bachelor' },
	{ id: StudyLevel.Master, name: 'Master' },
];

export const States = [
	{ id: EntityState.Active, name: 'Active' },
	{ id: EntityState.Inactive, name: 'Inactive' },
	{ id: EntityState.Removed, name: 'Removed' },
];

export function levelName(state: StudyLevel): string {
	switch (state) {
		case StudyLevel.Bachelor:
			return 'Bachelor';
		case StudyLevel.Master:
			return 'Master';
		default:
			return 'Unknown';
	}
}
export function levelNames(): string[] {
	return [
		levelName(StudyLevel.Bachelor),
		levelName(StudyLevel.Master),
		levelName(999),
	];
}

export function stateName(state: EntityState): string {
	switch (state) {
		case EntityState.Active:
			return 'Active';
		case EntityState.Inactive:
			return 'Inactive';
		case EntityState.Removed:
			return 'Removed';
		default:
			return 'Unknown';
	}
}

export function appVerdictName(state: VerdictStatus): string {
	switch (state) {
		case VerdictStatus.Accepted:
			return 'Accepted';
		case VerdictStatus.AcceptedUnderSpecialCircumstances:
			return 'Accepted under special circumstances';
		case VerdictStatus.Rejected:
			return 'Rejected';
		default:
			return 'Unhandled';
	}
}

export function appStatusName(state: ApplicationStatus): string {
	switch (state) {
		case ApplicationStatus.New:
			return 'New';
		case ApplicationStatus.IsBeingEdited:
			return 'Not submitted';
		case ApplicationStatus.PendingApproval:
			return 'Pending approval';
		case ApplicationStatus.Accepted:
			return 'Accepted';
		case ApplicationStatus.Closed:
			return 'Closed';
		case ApplicationStatus.Rejected:
			return 'Rejected';
		default:
			return 'Unknown status';
	}
}
export function getAppStatusNames(): string[] {
	return [
		appStatusName(ApplicationStatus.New),
		appStatusName(ApplicationStatus.IsBeingEdited),
		appStatusName(ApplicationStatus.PendingApproval),
		appStatusName(ApplicationStatus.Accepted),
		appStatusName(ApplicationStatus.Closed),
		appStatusName(ApplicationStatus.Rejected),
		appStatusName(999),
	];
}
export function appStatusDescription(state: ApplicationStatus): string {
	switch (state) {
		case ApplicationStatus.New:
			return 'The application is yet to be created';
		case ApplicationStatus.IsBeingEdited:
			return 'The application is being prepared by the student, or has been reviewed and subsequently been sent back to the student for further handling';
		case ApplicationStatus.PendingApproval:
			return 'The application is awaiting reviewal';
		case ApplicationStatus.Accepted:
			return 'The application has been accepted';
		case ApplicationStatus.Closed:
			return 'The application has been closed, with no action';
		case ApplicationStatus.Rejected:
			return 'The application has been rejected';
		default:
			return 'Unknown status';
	}
}
