import React from 'react';
import Application from '../components/application';
import { Typography } from '@material-ui/core';
import { useAuth } from '../contexts';
import { ApplicationHeadDTO } from '../DTO/ApplicationHeadDTO';

// default values
const privileges: Privileges = {
	addCourses: true,
	editRows: true,
	editCourses: true,
	giveVerdict: false,
};

const CreateApplication = () => {
	const { user } = useAuth();
	const [application, setApplication] = React.useState(
		user ? new ApplicationHeadDTO(user) : new ApplicationHeadDTO()
	);
	privileges.giveVerdict = !!user?.isAdmin;

	return (
		<div>
			<Typography
				style={{ marginTop: '20px' }}
				gutterBottom
				variant="h4"
				align="center"
			>
				New Application
			</Typography>
			<Application
				initialApplication={application}
				privileges={privileges}
			/>
		</div>
	);
};

export default CreateApplication;
