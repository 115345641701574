import React from 'react';
import { CourseDTO } from '../DTO/CourseDTO';
import { APIService } from '../services/apiService';

type State = CourseDTO[];
type Handlers = {
	getCourses: () => void;
	getCoursesBySchoolIds: (...schoolIds: number[]) => void;
	saveCourse: (course: CourseDTO) => Promise<CourseDTO>;
};

const CourseContext = React.createContext<State | undefined>(undefined);
const CourseUpdateContext = React.createContext<Handlers | undefined>(
	undefined
);

enum ActionType {
	SET_COURSES,
	ADD_COURSES,
	SAVE_COURSE,
}

type Action =
	| {
			type: ActionType.SET_COURSES;
			payload: CourseDTO[];
	  }
	| {
			type: ActionType.ADD_COURSES;
			payload: CourseDTO[];
	  }
	| {
			type: ActionType.SAVE_COURSE;
			payload: CourseDTO;
	  };

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case ActionType.SET_COURSES:
			return action.payload;

		case ActionType.ADD_COURSES:
			const includedCourses: { [key: string]: boolean } = {};
			return state.concat(action.payload).filter((c) => {
				if (!includedCourses[c.id]) {
					includedCourses[c.id] = true;
					return true;
				}
				return false;
			});

		case ActionType.SAVE_COURSE:
			if (state) {
				let found = false;
				const newCourses = state.map((s) => {
					if (s.id === action.payload.id) {
						found = true;
						return action.payload;
					} else return s;
				});
				if (!found) return [...state, action.payload];
				else return newCourses;
			}
		default:
			return state;
	}
};

const initialState: CourseDTO[] = [];
const api = new APIService();
export const CourseProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
		reducer,
		initialState
	);

	const getCourses = React.useCallback(() => {
		api.getCourses().then((data) => {
			dispatch({
				type: ActionType.SET_COURSES,
				payload: data,
			});
		});
	}, []);

	const getCoursesBySchoolIds = React.useCallback((...schoolIds) => {
		Promise.all(schoolIds.map((id) => api.getCoursesBySchoolId(id))).then(
			(schools) => {
				dispatch({
					type: ActionType.ADD_COURSES,
					payload: schools.flat(),
				});
			}
		);
	}, []);

	const saveCourse = React.useCallback((course: CourseDTO) => {
		return api.saveCourse(course).then((savedCourse) => {
			dispatch({
				type: ActionType.SAVE_COURSE,
				payload: savedCourse,
			});
			return savedCourse;
		});
	}, []);

	return (
		<CourseContext.Provider value={state}>
			<CourseUpdateContext.Provider
				value={{ getCourses, getCoursesBySchoolIds, saveCourse }}
			>
				{children}
			</CourseUpdateContext.Provider>
		</CourseContext.Provider>
	);
};

export const useCourses = () => {
	const value = React.useContext(CourseContext);

	if (!value) {
		throw new Error('CourseContext must be used within a CourseProvider');
	}

	return value;
};

export const useUpdateCourses = () => {
	const value = React.useContext(CourseUpdateContext);

	if (!value) {
		throw new Error(
			'CourseUpdateContext must be used within a CourseUpdateProvider'
		);
	}

	return value;
};
