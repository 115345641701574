import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { ApplicationHeadDTO } from '../../DTO/ApplicationHeadDTO';
import { ApplicationRowDTO } from '../../DTO/ApplicationRowDTO';
import { CourseDTO } from '../../DTO/CourseDTO';
import { ApplicationStatus, EntityState } from '../../util/enumerations';
import { APIService } from '../../services/apiService';
import { HOME_SCHOOL_ID, ROUTES } from '../../constants';

import ApplicationHead from './head/applicationHead';
import ApplicationRows from './row/applicationRows';
import ApplicationControls from './applicationControls';
import { InformationRowDTO } from '../../DTO/InformationRowDTO';
import { useCourses, useSchools, useUpdateCourses } from '../../contexts';
import { VerdictDTO } from '../../DTO/VerdictDTO';
import { SchoolDTO } from '../../DTO/SchoolDTO';
import { validate } from './util';
import { ApplicationState, IApplicationState } from './ApplicationState';
import {
	ApplicationHandlers,
	IApplicationHandlers,
} from './ApplicationHandlers';
import { useIsDirty } from '../../hooks/isDirty';

export enum CourseOrigin {
	HOME = 'coursesHome',
	EXCHANGE = 'coursesExchange',
}

enum ActionType {
	SET_APPLICATION,
	SET_SCHOOL,
	REMOVE_ROW,
	ADD_ROW,
	SET_STATUS,
	SET_ACTIVE_VERDICT,
	SAVE_COMMENT,
	SAVE_LINK,
	ADD_COURSE,
	REMOVE_COURSE,
	UPDATE_COURSE,
	UPDATE_INFORMATION_ROW,
	UPDATE_INFORMATION_ROW_URL,
	SET_YEAR,
	SET_MASTER_TRACK_ID,
}

// prettier-ignore
type Action = 
	| { type: ActionType.SET_APPLICATION; payload: ApplicationHeadDTO; }
	| { type: ActionType.SET_SCHOOL; payload: SchoolDTO; }
	| { type: ActionType.REMOVE_ROW; payload: { idx: number; toState: EntityState }; }
	| { type: ActionType.ADD_ROW; }
	| { type: ActionType.SET_STATUS; payload: ApplicationStatus; }
	| { type: ActionType.SET_ACTIVE_VERDICT; payload: { idx: number; verdict: VerdictDTO }; }
	| { type: ActionType.SAVE_COMMENT; payload: { idx: number; comment: string }; }
	| { type: ActionType.SAVE_LINK; payload: { idx: number; externalUrl: string }; }
	| { type: ActionType.ADD_COURSE; payload: { idx: number; key: CourseOrigin }; }
	| { type: ActionType.REMOVE_COURSE; payload: { rowIdx: number; key: CourseOrigin; courseIdx: number; courseId: number; }; }
	| { type: ActionType.UPDATE_COURSE; payload: { rowIdx: number; key: CourseOrigin; courseIdx: number; id: number; oldId: number; newCourse?: CourseDTO; }; }
	| { type: ActionType.UPDATE_INFORMATION_ROW; payload: { rowIdx: number; text: string; idx: number }; }
	| { type: ActionType.UPDATE_INFORMATION_ROW_URL; payload: { rowIdx: number; url: string; idx: number }; }
	| { type: ActionType.SET_YEAR; payload: number; }
	| { type: ActionType.SET_MASTER_TRACK_ID; payload: number; };

const reducer = (state: ApplicationHeadDTO, action: Action) => {
	switch (action.type) {
		case ActionType.SET_APPLICATION:
			return action.payload;
		case ActionType.SET_SCHOOL:
			return {
				...state,
				applicationRows:
					state.applicationRows.length === 0
						? [new ApplicationRowDTO()]
						: state.applicationRows, // TODO: Is this ok? Courses may become invalid due to new school
				school: action.payload,
				schoolId: action.payload.id,
			};

		case ActionType.REMOVE_ROW:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, i) =>
					i === action.payload.idx
						? {
								...r,
								state: action.payload.toState,
								isModified: true,
						  }
						: r
				),
			};

		case ActionType.ADD_ROW:
			return {
				...state,
				applicationRows: [
					...state.applicationRows,
					new ApplicationRowDTO(),
				],
			};

		case ActionType.SET_STATUS:
			return { ...state, status: action.payload };

		case ActionType.SET_ACTIVE_VERDICT:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, i) =>
					i === action.payload.idx
						? {
								...r,
								activeVerdict: action.payload.verdict,
						  }
						: r
				),
			};

		case ActionType.SAVE_COMMENT:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, i) =>
					i === action.payload.idx
						? {
								...r,
								comment: action.payload.comment,
								isModified: true,
						  }
						: r
				),
			};

		case ActionType.SAVE_LINK:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, i) =>
					i === action.payload.idx
						? {
								...r,
								externalUrl: action.payload.externalUrl,
								isModified: true,
						  }
						: r
				),
			};

		case ActionType.ADD_COURSE:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, i) =>
					i === action.payload.idx
						? {
								...r,
								isModified: true,
								courseMappingHeadId: 0,
								[action.payload.key]: [
									...r[action.payload.key],
									new CourseDTO(),
								],
						  }
						: r
				),
			};

		case ActionType.REMOVE_COURSE:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, rIdx) =>
					rIdx === action.payload.rowIdx
						? {
								...r,
								isModified: true,
								courseMappingHeadId: 0,
								[action.payload.key]: r[
									action.payload.key
								].filter(
									(_, cIdx) =>
										cIdx !== action.payload.courseIdx
								),
						  }
						: r
				),
			};

		case ActionType.UPDATE_COURSE:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, rIdx) =>
					action.payload.rowIdx === rIdx
						? {
								...r,
								isModified: true,
								courseMappingHeadId: 0,
								[action.payload.key]: r[action.payload.key].map(
									(c, cIdx) =>
										cIdx === action.payload.courseIdx
											? {
													...action.payload.newCourse,
													informationRow:
														new InformationRowDTO(
															action.payload.id
														),
											  }
											: c
								),
						  }
						: r
				),
			};

		case ActionType.UPDATE_INFORMATION_ROW:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, rIdx) =>
					rIdx === action.payload.rowIdx
						? {
								...r,
								isModified: true,
								coursesExchange: r.coursesExchange.map(
									(c, cIdx) =>
										cIdx === action.payload.idx
											? ({
													...c,
													informationRow: {
														...c.informationRow,
														note: action.payload
															.text,
													},
											  } as CourseDTO)
											: c
								),
						  }
						: r
				),
			};

		case ActionType.UPDATE_INFORMATION_ROW_URL:
			return {
				...state,
				applicationRows: state.applicationRows.map((r, rIdx) =>
					rIdx === action.payload.rowIdx
						? {
								...r,
								isModified: true,
								coursesExchange: r.coursesExchange.map(
									(c, cIdx) =>
										cIdx === action.payload.idx
											? ({
													...c,
													informationRow: {
														...c.informationRow,
														externalUrl:
															action.payload.url,
													},
											  } as CourseDTO)
											: c
								),
						  }
						: r
				),
			};

		case ActionType.SET_YEAR:
			return {
				...state,
				year: action.payload,
			};

		case ActionType.SET_MASTER_TRACK_ID:
			return {
				...state,
				masterTrackId: action.payload,
			};

		default:
			return state;
	}
};

export const ApplicationContext = React.createContext<
	IApplicationState | undefined
>(undefined);
export const ApplicationUpdateContext = React.createContext<
	IApplicationHandlers | undefined
>(undefined);

const api = new APIService();
type ApplicationProps = {
	privileges: Privileges;
	initialApplication?: ApplicationHeadDTO;
	onStatusUpdate?: (newStatus: ApplicationStatus) => void;
};
const ApplicationContainer = ({
	onStatusUpdate,
	privileges,
	initialApplication,
}: ApplicationProps) => {
	const [application, dispatch] = React.useReducer<
		React.Reducer<ApplicationHeadDTO, Action>
	>(reducer, initialApplication || new ApplicationHeadDTO());

	const schools = useSchools();
	const courses = useCourses();
	const { getCoursesBySchoolIds } = useUpdateCourses();
	const history = useHistory();
	const [serverMessage, setServerMessage] = useState<string | null>(null);
	const [validationMessage, setValidationMessage] = useState('');
	const [isDirty, updateCleanValue] = useIsDirty(application);

	useEffect(() => {
		onStatusUpdate?.(application.status);
	}, [application.status]);

	useEffect(() => {
		if (privileges.editCourses && application?.school?.id) {
			getCoursesBySchoolIds(HOME_SCHOOL_ID, application.school.id);
		}
	}, [privileges.editCourses, application.school?.id]);

	const handleSelectSchool = React.useCallback(
		(school: SchoolDTO) => {
			const newSchool = schools?.find((s) => Number(s.id) === school.id);
			if (newSchool) {
				dispatch({
					type: ActionType.SET_SCHOOL,
					payload: newSchool,
				});
			}
		},
		[schools]
	);

	const saveApplication = React.useCallback(() => {
		api.saveApplication(application)
			.then((app) => {
				updateCleanValue(app);
				dispatch({ type: ActionType.SET_APPLICATION, payload: app });
				if (application.id === 0) {
					history.replace(`${ROUTES.APPLICATION}/${app.id}`);
				}
			})
			.catch((ex) => setServerMessage(ex.response.data.message));
	}, [application]);

	const removeRow = React.useCallback(
		(idx: number, toState = EntityState.Removed) => {
			dispatch({
				type: ActionType.REMOVE_ROW,
				payload: { idx, toState },
			});
		},
		[]
	);

	const setApplicationStatus = React.useCallback(
		(toStatus: ApplicationStatus) => {
			const validation = validate(application);
			if (!validation) {
				api.setApplicationStatus(application.id, toStatus)
					.then((result) => {
						dispatch({
							type: ActionType.SET_STATUS,
							payload: toStatus,
						});
						updateCleanValue({ ...application, status: toStatus });
					})
					.catch((ex) => setServerMessage(ex.response.data.message));
			}
			setValidationMessage(validation);
		},
		[application]
	);

	const addRow = React.useCallback(() => {
		dispatch({ type: ActionType.ADD_ROW });
	}, []);

	const giveVerdict = React.useCallback(
		(idx: number, verdict: VerdictDTO) => {
			dispatch({
				type: ActionType.SET_ACTIVE_VERDICT,
				payload: { idx, verdict },
			});
		},
		[]
	);

	const saveComment = React.useCallback((idx: number, comment: string) => {
		dispatch({ type: ActionType.SAVE_COMMENT, payload: { idx, comment } });
	}, []);

	const saveLink = React.useCallback((idx: number, externalUrl: string) => {
		dispatch({ type: ActionType.SAVE_LINK, payload: { idx, externalUrl } });
	}, []);

	const addCourse = React.useCallback(
		(idx: number, key: CourseOrigin) => () => {
			dispatch({ type: ActionType.ADD_COURSE, payload: { idx, key } });
		},
		[]
	);

	const removeCourse = React.useCallback(
		(rowIdx: number, key: CourseOrigin) =>
			(courseIdx: number, courseId: number) => {
				dispatch({
					type: ActionType.REMOVE_COURSE,
					payload: { rowIdx, key, courseIdx, courseId },
				});
			},
		[]
	);

	const updateCourse = React.useCallback(
		(rowIdx: number, key: CourseOrigin) =>
			(courseIdx: number, id: number, oldId: number) => {
				dispatch({
					type: ActionType.UPDATE_COURSE,
					payload: {
						rowIdx,
						key,
						courseIdx,
						id,
						oldId,
						newCourse: courses?.find((c) => c.id == id),
					},
				});
			},
		[courses]
	);

	const updateInformationRow = React.useCallback(
		(rowIdx: number, text: string, idx: number) => {
			dispatch({
				type: ActionType.UPDATE_INFORMATION_ROW,
				payload: { rowIdx, text, idx },
			});
		},
		[]
	);

	const updateInformationRowUrl = React.useCallback(
		(rowIdx: number, url: string, idx: number) => {
			dispatch({
				type: ActionType.UPDATE_INFORMATION_ROW_URL,
				payload: { rowIdx, url, idx },
			});
		},
		[]
	);

	const setYear = React.useCallback((year: number) => {
		dispatch({ type: ActionType.SET_YEAR, payload: year });
	}, []);

	const setMasterProgram = React.useCallback((masterTrackId: number) => {
		dispatch({
			type: ActionType.SET_MASTER_TRACK_ID,
			payload: masterTrackId,
		});
	}, []);

	const state = new ApplicationState({
		application,
		privileges,
		validationMessage,
		isDirty,
		serverMessage,
	});
	const handlers = new ApplicationHandlers({
		setYear,
		setMasterProgram,
		handleSelectSchool,
		saveApplication,
		setApplicationStatus,
		giveVerdict,
		saveComment,
		saveLink,
		addCourse,
		removeCourse,
		updateCourse,
		updateInformationRow,
		updateInformationRowUrl,
		removeRow,
		addRow,
	});

	if (!schools) return <p>Hämtar skolor...</p>;

	return (
		<ApplicationContext.Provider value={state}>
			<ApplicationUpdateContext.Provider value={handlers}>
				<ApplicationHead />
				<ApplicationRows />
				<ApplicationControls />
			</ApplicationUpdateContext.Provider>
		</ApplicationContext.Provider>
	);
};

export const useApplication = () => {
	const value = React.useContext(ApplicationContext);

	if (!value) {
		throw new Error(
			'ApplicationContext must be used within an ApplicationProvider'
		);
	}

	return value;
};

export const useUpdateApplication = () => {
	const value = React.useContext(ApplicationUpdateContext);

	if (!value) {
		throw new Error(
			'ApplicationContext must be used within an ApplicationProvider'
		);
	}

	return value;
};

export default ApplicationContainer;
