import React from 'react';
import { UserDTO } from '../DTO/UserDTO';
import { APIService } from '../services/apiService';

type State = {
	authenticated?: boolean;
	user: UserDTO | null;
};
type Handlers = {
	getSessionUser: () => void;
	login: () => void;
	logout: () => void;
};

const AuthContext = React.createContext<State | undefined>(undefined);
const AuthUpdateContext = React.createContext<Handlers | undefined>(undefined);

enum ActionType {
	ACTIVE_USER,
	NO_ACTIVE_USER,
	LOGIN,
	LOGOUT,
}

type Action =
	| {
			type: ActionType.ACTIVE_USER;
			payload: UserDTO;
	  }
	| {
			type: ActionType.NO_ACTIVE_USER;
	  }
	| {
			type: ActionType.LOGIN;
			payload: UserDTO;
	  }
	| {
			type: ActionType.LOGOUT;
	  };

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case ActionType.ACTIVE_USER:
			return {
				authenticated: !!action.payload,
				user: action.payload,
			};
		case ActionType.NO_ACTIVE_USER:
			return {
				...state,
				authenticated: false,
			};
		case ActionType.LOGIN:
			return {
				...state,
				authenticated: true,
			};
		case ActionType.LOGOUT:
			return {
				...state,
				authenticated: false,
			};
		default:
			return state;
	}
};

const initialState = {
	user: null,
};

const api = new APIService();
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
		reducer,
		initialState
	);

	const getSessionUser = React.useCallback(() => {
		api.getCurrentUser()
			.then((user) => {
				dispatch({ type: ActionType.ACTIVE_USER, payload: user });
			})
			.catch((_err) => {
				dispatch({ type: ActionType.NO_ACTIVE_USER });
			});
	}, []);

	React.useEffect(() => {
		getSessionUser();
	}, [getSessionUser]);

	const login = React.useCallback(() => {
		api.login().then((user) => {
			dispatch({ type: ActionType.LOGIN, payload: user });
		});
	}, []);

	const logout = React.useCallback(() => {
		api.logout().then((x) => {
			dispatch({ type: ActionType.LOGOUT });
		});
	}, []);

	return (
		<AuthContext.Provider value={state}>
			<AuthUpdateContext.Provider
				value={{ getSessionUser, login, logout }}
			>
				{children}
			</AuthUpdateContext.Provider>
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const value = React.useContext(AuthContext);

	if (!value) {
		throw new Error('AuthContext must be used within an AuthProvider');
	}

	return value;
};

export const useUpdateAuth = () => {
	const value = React.useContext(AuthUpdateContext);

	if (!value) {
		throw new Error(
			'AuthUpdateContext must be used within a AuthUpdateProvider'
		);
	}

	return value;
};
