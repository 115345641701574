import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';

import { ROUTES } from './constants';

import { Container } from './components/_atoms';
import NavBar from './components/NavBar';
import ProtectedRoute from './components/protectedRoute';

import Applications from './pages/applications';
import CreateApplication from './pages/createApplication';
import SingleApplication from './pages/application';
import Schools from './pages/schools';
import Courses from './pages/courses';
import Course from './pages/course';
import NotFound from './pages/notFound';
import Users from './pages/users';
import School from './pages/school';
import User from './pages/user';
import Home from './pages/home';
import { Grid } from '@material-ui/core';

import ApplicationsAdmin from './pages/applicationsAdmin';
import ApplicationRowsAdmin from './pages/applicationRowsAdmin';
import MasterTracks from './pages/masterTracks';
import MasterTrack from './pages/masterTrack';
import SideMenu from './components/sideMenu/sideMenu';

const App = () => {
	const [sideMenuIsOpen, setSideMenuIsOpen] = React.useState<boolean>(false);

	const toggleSideMenu = () => {
		setSideMenuIsOpen(!sideMenuIsOpen);
	};

	return (
		<Container>
			<NavBar toggleSideMenu={toggleSideMenu} />
			<Grid container style={{ justifyContent: 'center' }}>
				<Grid
					item
					style={{
						marginTop: 70,
						padding: 20,
						backgroundColor: 'white',
						width: '1400px',
						maxWidth: '95vw',
					}}
				>
					<Switch>
						<ProtectedRoute
							path={`${ROUTES.APPLICATION}/:id`}
							component={SingleApplication}
						/>
						<ProtectedRoute
							path={ROUTES.APPLICATIONS}
							component={Applications}
						/>
						<ProtectedRoute
							path={ROUTES.APPLICATIONS_ADMIN}
							component={ApplicationsAdmin}
							isAdmin
						/>
						<ProtectedRoute
							path={ROUTES.CREATE_APPLICATION}
							component={CreateApplication}
						/>
						<ProtectedRoute
							path={ROUTES.APPLICATION_ROWS}
							component={ApplicationRowsAdmin}
							isAdmin
						/>
						<ProtectedRoute
							path={ROUTES.SCHOOLS}
							component={Schools}
							isAdmin
						/>
						<ProtectedRoute
							path={`${ROUTES.SCHOOL}/:id`}
							component={School}
							isAdmin
						/>
						<ProtectedRoute
							path={`${ROUTES.COURSES}/:schoolId?`}
							component={Courses}
							isAdmin
						/>
						<ProtectedRoute
							path={`${ROUTES.COURSE}/:courseId`}
							component={Course}
							isAdmin
						/>
						<ProtectedRoute
							path={ROUTES.USERS}
							component={Users}
							isAdmin
						/>
						<ProtectedRoute
							path={`${ROUTES.USER}/:id`}
							component={User}
							isAdmin
						/>
						<ProtectedRoute
							path={`${ROUTES.MASTERTRACK}/:id`}
							component={MasterTrack}
							isAdmin
						/>
						<ProtectedRoute
							path={`${ROUTES.MASTERTRACKS}`}
							component={MasterTracks}
							isAdmin
						/>
						<Route exact path="/" component={Home} />
						<Route component={NotFound} />
					</Switch>
				</Grid>
			</Grid>
			<SideMenu isOpen={sideMenuIsOpen} toggleOpen={toggleSideMenu} />
		</Container>
	);
};

export default App;
