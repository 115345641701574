import { StudyLevel } from '../util/enumerations';
import { IBaseDTO, BaseDTO } from './BaseDTO';
import { DocumentDTO } from './DocumentDTO';
import { InformationRowDTO } from './InformationRowDTO';
import { SchoolDTO } from './SchoolDTO';

export interface ICourseDTO extends IBaseDTO {
	id: number;
	schoolId: number;
	courseMappingHeadId?: number;
	code: string;
	title: string;
	localCredits: number;
	ectsCredits?: number;
	level: StudyLevel;
	documents?: DocumentDTO[];
	display?: boolean;
	school: SchoolDTO;
	informationRow?: InformationRowDTO;
}

export class CourseDTO extends BaseDTO implements ICourseDTO {
	id: number;
	schoolId: number;
	courseMappingHeadId?: number;
	code: string;
	title: string;
	localCredits: number;
	ectsCredits: number;
	level: StudyLevel;
	documents?: DocumentDTO[];
	display?: boolean;
	school: SchoolDTO;
	informationRow?: InformationRowDTO;

	constructor(schoolId?: number) {
		super();
		this.id = 0;
		this.schoolId = schoolId || 0;
		this.code = '';
		this.title = '';
		this.localCredits = 0;
		this.ectsCredits = 0;
		this.level = StudyLevel.Unknown;
		this.display = false;
	}
}
