import React from 'react';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import { ApplicationStatus } from '../../../util/enumerations';
import { useUpdateApplication } from '..';

export const SetStatus = () => {
	const { setApplicationStatus } = useUpdateApplication();

	return React.useMemo(
		() => (
			<PopupState variant="popover" popupId="demo-popup-menu">
				{(popupState) => (
					<React.Fragment>
						<Button
							style={{ marginLeft: '10px' }}
							variant="contained"
							color="primary"
							{...bindTrigger(popupState)}
						>
							Admin: Set status
						</Button>
						<Menu {...bindMenu(popupState)}>
							<MenuItem
								onClick={() => {
									setApplicationStatus(ApplicationStatus.New);
								}}
							>
								New
							</MenuItem>
							<MenuItem
								onClick={() => {
									setApplicationStatus(
										ApplicationStatus.IsBeingEdited
									);
								}}
							>
								Under preparation
							</MenuItem>
							<MenuItem
								onClick={() => {
									setApplicationStatus(
										ApplicationStatus.PendingApproval
									);
								}}
							>
								Pending approval
							</MenuItem>
							<MenuItem
								onClick={() => {
									setApplicationStatus(
										ApplicationStatus.Accepted
									);
								}}
							>
								Accepted
							</MenuItem>
							<MenuItem
								onClick={() => {
									setApplicationStatus(
										ApplicationStatus.Rejected
									);
								}}
							>
								Rejected
							</MenuItem>
							<MenuItem
								onClick={() => {
									setApplicationStatus(
										ApplicationStatus.Closed
									);
								}}
							>
								Closed
							</MenuItem>
						</Menu>
					</React.Fragment>
				)}
			</PopupState>
		),
		[setApplicationStatus]
	);
};
