import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';

import { Container } from '../components/_atoms';
import { ROUTES } from '../constants';
import { SchoolDTO } from '../DTO/SchoolDTO';
import LastEdited from '../components/lastEdited';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormGroup, makeStyles, Typography } from '@material-ui/core';
import { APIService } from '../services/apiService';
import { CountryDTO } from '../DTO/CountryDTO';
import { useSchools, useUpdateSchools } from '../contexts';
import { useIsDirty } from '../hooks/isDirty';

const useStyles = makeStyles({
	button: {
		margin: '12px 6px 18px 0',
	},
	field: {
		marginBottom: '12px',
		marginRight: '12px',
		display: 'block',
	},
});

const api = new APIService();

const School = () => {
	const match = useRouteMatch<{ id?: string }>();
	const history = useHistory();
	const { saveSchool } = useUpdateSchools();
	const schools = useSchools();
	const schoolId: number = match.params.id ? parseInt(match.params.id) : 0;

	const [school, setSchool] = useState<SchoolDTO>(
		schoolId > 0
			? schools?.find((s) => s.id == schoolId) || new SchoolDTO()
			: new SchoolDTO()
	);
	const [error, setError] = useState('');
	const [countries, setCountries] = useState<null | CountryDTO[]>(null);
	const classes = useStyles();
	const [isDirty, updateCleanValue] = useIsDirty(school);

	useEffect(() => {
		if (countries === null) {
			api.getCountries().then((data) => setCountries(data));
		}
	});

	useEffect(() => {
		const parsedId = match.params.id && parseInt(match.params.id);
		if (parsedId && parsedId > 0 && schools) {
			const activeSchool = schools?.find((s) => s.id == parsedId);
			if (activeSchool) {
				updateCleanValue(activeSchool);
				setSchool(activeSchool);
			}
		}
	}, [match.params?.id, schools]);

	const handleSubmit = (e: React.FormEvent) => {
		saveSchool(school)
			.then((newSchool) => {
				setSchool(newSchool);
				updateCleanValue(newSchool);
				if (school.id === 0) {
					history.replace(`${ROUTES.SCHOOL}/${newSchool.id}`);
				}
			})
			.catch((ex) => {
				setError(ex.response.data.message);
			});
		e.preventDefault();
	};

	const setSchoolName = (name: string) => {
		setSchool((prevSchool) => ({ ...prevSchool, name }));
	};

	return !school ? (
		<h1>Could not find school</h1>
	) : (
		<Container>
			<Typography
				gutterBottom
				style={{ marginTop: '5px' }}
				variant="h4"
				align="center"
			>
				{schoolId === 0 ? 'Create New ' : ''}University
			</Typography>
			<Button
				variant="contained"
				color="secondary"
				size="small"
				className={classes.button}
				component={Link}
				to={`${ROUTES.COURSES}/${school.id}`}
			>
				Courses
			</Button>
			<form onSubmit={handleSubmit}>
				<FormGroup row>
					<TextField
						type="text"
						label="Name"
						variant="outlined"
						className={classes.field}
						value={school.name}
						onChange={(e) => setSchoolName(e.target.value)}
					/>
					{countries && countries.length > 0 ? (
						<Autocomplete
							style={{ width: '200px' }}
							value={school?.country?.name}
							onChange={(event, newValue) => {
								if (!countries) return;
								const country = countries.find(
									(c) => c.name == newValue
								);
								setSchool(
									(prevSchool) =>
										({
											...prevSchool,
											country,
											countryId: country?.id,
										} as SchoolDTO)
								);
							}}
							options={countries.map((option) => option.name)}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Country"
									variant="outlined"
									className={classes.field}
								/>
							)}
						/>
					) : null}
				</FormGroup>
				<Button
					size="small"
					variant="contained"
					color="primary"
					type="submit"
					className={classes.button}
					disabled={!isDirty}
				>
					Save
				</Button>
			</form>

			<LastEdited data={school}></LastEdited>
			<p>{error}</p>
		</Container>
	);
};

export default School;
