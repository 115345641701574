import React from 'react';

import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
	ApplicationStatus,
	appStatusDescription,
	appStatusName,
} from '../../../util/enumerations';
import CreateCourses from '../createCourses';
import { APIService } from '../../../services/apiService';
import { useApplication, useUpdateApplication } from '..';
import { ApplicationSummary } from './applicationSummary';
import { SetStatus } from './setStatus';
import { ApplicationStepper } from './applicationStepper';
import { ApplicationHeadFields } from './applicationHeadFields';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from '../../../contexts';

const api = new APIService();

const ApplicationStatusDescription = () => {
	const { application } = useApplication();
	return React.useMemo(
		() =>
			application.status !== ApplicationStatus.New ? (
				<FormGroup row>
					<Alert
						severity="info"
						style={{ width: '100%', margin: '20px' }}
					>
						<AlertTitle>
							{appStatusName(application.status)}
						</AlertTitle>
						{appStatusDescription(application.status)}
					</Alert>
				</FormGroup>
			) : null,
		[application.status]
	);
};

const ApplicationValidation = () => {
	const { validationMessage } = useApplication();

	const content = React.useMemo(
		() => (
			<Alert style={{ margin: '20px' }} severity="error">
				<AlertTitle>Please review before submitting</AlertTitle>
				<Typography
					style={{ whiteSpace: 'pre-line' }}
				>{`${validationMessage}`}</Typography>
			</Alert>
		),
		[validationMessage]
	);

	return validationMessage ? content : null;
};

const ApplicationIsDirtyIndicator = () => {
	const { isDirty } = useApplication();
	const content = React.useMemo(
		() => (
			<Alert style={{ margin: '20px' }} severity="info">
				<AlertTitle>Info</AlertTitle>
				There are unsaved changes
			</Alert>
		),
		[]
	);
	return isDirty ? content : null;
};

const SaveApplication = () => {
	const { privileges, application, isDirty } = useApplication();
	const { saveApplication } = useUpdateApplication();

	return privileges.editCourses && application.schoolId !== undefined ? (
		<Button
			onClick={() => saveApplication()}
			variant="contained"
			color="primary"
			style={{ marginRight: '10px' }}
			disabled={application.schoolId < 1 || !isDirty}
		>
			Save
		</Button>
	) : null;
};

const ApplicationHeadControls = () => {
	const { user } = useAuth();
	const { application, isDirty, privileges } = useApplication();
	const { setApplicationStatus } = useUpdateApplication();

	return React.useMemo(
		() => (
			<>
				<SaveApplication />
				{application.status < 2 ? (
					<Button
						onClick={() =>
							setApplicationStatus(
								ApplicationStatus.PendingApproval
							)
						}
						variant="contained"
						color="primary"
						disabled={!application.id || isDirty}
					>
						Submit
					</Button>
				) : null}
				{application.status === ApplicationStatus.PendingApproval ? (
					<Button
						onClick={() =>
							setApplicationStatus(
								ApplicationStatus.IsBeingEdited
							)
						}
						variant="contained"
						color="primary"
					>
						Unsubmit
					</Button>
				) : null}
				<Button
					variant="contained"
					style={{ marginLeft: '10px' }}
					color="secondary"
					disabled={application.id === 0 || isDirty}
					href={api.downloadApplicationHeadById(application.id)}
				>
					Print
				</Button>
				{user?.isAdmin && <SetStatus />}
			</>
		),
		[
			application.status,
			setApplicationStatus,
			isDirty,
			privileges.giveVerdict,
		]
	);
};

const useStyles = makeStyles({
	controlsFormGroup: {
		marginTop: '20px',
		marginBottom: '20px',
		marginRight: '20px',
	},
});

const ApplicationHead = () => {
	const classes = useStyles();
	const { application, privileges } = useApplication();

	const content = React.useMemo(
		() => (
			<div>
				<FormGroup row>
					<ApplicationStepper />
				</FormGroup>
				<ApplicationStatusDescription />
				{application.id > 0 ? <ApplicationSummary /> : null}
				<ApplicationHeadFields />
				<ApplicationValidation />
				<ApplicationIsDirtyIndicator />
				<FormGroup row className={classes.controlsFormGroup}>
					<ApplicationHeadControls />
				</FormGroup>
				{privileges.addCourses &&
				application.applicationRows.length > 0 &&
				application.school &&
				application.schoolId !== undefined ? (
					<CreateCourses
						schoolId={application.schoolId}
						schoolName={application.school.name}
					/>
				) : null}
			</div>
		),
		[
			application.id,
			application.school?.name,
			application.user?.name,
			application.school,
			privileges.addCourses,
			application.applicationRows.length,
			application.schoolId,
		]
	);

	if (!application.user) return <p>No user</p>;
	return content;
};

export default ApplicationHead;
