import React from 'react';

import { Box, Button, DialogTitle, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Course from '.';

import { CourseDTO } from '../../DTO/CourseDTO';
import { HOME_SCHOOL_ID } from '../../constants';

type ModalProps = {
	schoolId: number;
	open: boolean;
	onClose: () => void;
	initialCourse?: CourseDTO;
};

const CourseModal = ({
	schoolId,
	open,
	onClose,
	initialCourse,
}: ModalProps) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<div style={{ padding: '15px' }}>
				<DialogTitle>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
					>
						<Box flexGrow={1}>
							Create new{' '}
							{schoolId == HOME_SCHOOL_ID ? 'home' : 'exchange'}{' '}
							course
						</Box>
						<Box>
							<IconButton onClick={onClose}>
								<CloseIcon />
							</IconButton>
						</Box>
					</Box>
				</DialogTitle>
				<Course
					schoolId={schoolId}
					{...(initialCourse && { initialCourse })}
					onCreateCourse={onClose}
				/>
			</div>
		</Dialog>
	);
};

export default CourseModal;
