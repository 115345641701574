import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { APIService } from '../services/apiService';
import { ROUTES } from '../constants';
import { ApplicationHeadDTO } from '../DTO/ApplicationHeadDTO';
import {
	Button,
	Card,
	Container,
	FormGroup,
	IconButton,
	TextField,
	Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import { appStatusName } from '../util/enumerations';
import { Alert, AlertTitle } from '@material-ui/lab';

const margin = {
	marginBottom: '12px',
	marginRight: '12px',
};
const api = new APIService();

const Applications = () => {
	const history = useHistory();
	// TODO: Remove this
	const [applications, setApplications] = useState<
		ApplicationHeadDTO[] | null
	>(null);
	const [newClicked, setNewClicked] = useState<boolean>(false);

	const getApplications = () => {
		api.getApplications().then((data) => setApplications(data));
	};

	const createApplication = () => {
		if (newClicked == false && (applications || []).length > 0) {
			setNewClicked(true);
		} else {
			history.push(ROUTES.CREATE_APPLICATION);
		}
	};
	useEffect(() => {
		getApplications();
	}, []);

	if (!applications) return <p>Hämtar ansökningar...</p>;

	return (
		<>
			{(newClicked === true && applications?.length) || 0 > 0 ? (
				<Alert severity="warning">
					<AlertTitle>Warning</AlertTitle>
					You already have an active application —{' '}
					<strong> are you sure you want to continue?</strong>
				</Alert>
			) : null}
			<Container style={{ textAlign: 'center' }}>
				<Button
					variant="contained"
					color="secondary"
					size="large"
					style={{ margin: '20px' }}
					onClick={(e) => createApplication()}
					startIcon={<AddCircleOutlineIcon />}
				>
					New application
				</Button>
				{applications.length > 0 &&
					applications.map((a) => (
						<Card
							key={a.id}
							elevation={5}
							style={{
								padding: '10px',
								marginBottom: '20px',
								marginTop: '10px',
							}}
						>
							<Typography variant="h5" style={{ margin: '10px' }}>
								{`Application ${a.id}`}
							</Typography>
							<FormGroup
								row
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<TextField
									type="text"
									value={a?.school?.name}
									inputProps={{ readOnly: true }}
									label="School"
									style={margin}
								/>
								<TextField
									type="text"
									value={a?.school?.country?.name}
									label="Country"
									style={margin}
									inputProps={{ readOnly: true }}
								/>
								<TextField
									type="text"
									value={a?.year}
									label="Year"
									style={margin}
									inputProps={{ readOnly: true }}
								/>
								<TextField
									type="text"
									value={appStatusName(a?.status)}
									label="Status"
									style={margin}
									inputProps={{ readOnly: true }}
								/>
							</FormGroup>
							<Link
								to={`${ROUTES.APPLICATION}/${a.id}`}
								key={a.id}
								className="no-link-style"
							>
								<IconButton aria-label="delete" color="primary">
									<EditIcon fontSize="large" />
								</IconButton>
							</Link>
						</Card>
					))}
			</Container>
		</>
	);
};

export default Applications;
