import React from 'react';
import { Container } from './_atoms';

interface ILastEditedProps {
	data: {
		[key: string]: any;
		created: Date;
		createdBy: string;
		modified: Date;
		modifiedBy: string;
	};
}

const pad = (s: number | string) => `0${s}`.slice(-2);
const formatDate = (d: Date) =>
	`${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(
		d.getHours()
	)}:${pad(d.getMinutes())}`;

const LastEdited = (props: ILastEditedProps) => {
	const { created, createdBy, modified, modifiedBy } = props.data;

	return (
		<Container>
			{created && createdBy && (
				<p>
					{'Created by '} <strong>{createdBy}</strong> {' at '}
					{formatDate(new Date(created))}
				</p>
			)}
			{modified && modifiedBy && (
				<p>
					{'Last modified by '} <strong>{modifiedBy}</strong> {' at '}
					{formatDate(new Date(modified))}
				</p>
			)}
		</Container>
	);
};

export default LastEdited;
