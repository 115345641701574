import { HttpService } from './httpService';
import { ApplicationHeadDTO } from '../DTO/ApplicationHeadDTO';
import { SchoolDTO } from '../DTO/SchoolDTO';
import { CourseDTO } from '../DTO/CourseDTO';
import { ApplicationRowExDTO } from '../DTO/ApplicationRowExDTO';
import { DocumentDTO } from '../DTO/DocumentDTO';
import { UserDTO } from '../DTO/UserDTO';
import { VerdictDTO } from '../DTO/VerdictDTO';
import { CountryDTO } from '../DTO/CountryDTO';
import { getApiPath } from '../util';
import { ApplicationStatus } from '../util/enumerations';
import { ActionResult } from '../DTO/ActionResult';
import { MasterTrackDTO } from '../DTO/MasterTrackDTO';

export class APIService extends HttpService {
	public constructor() {
		super(getApiPath());
	}

	//#region Application
	//GET
	public getApplications = async () =>
		this.instance.get<ApplicationHeadDTO[]>(`/application/head/all`);
	public getApplicationById = async (id: number) =>
		this.instance.get<ApplicationHeadDTO>(`/application/head/byId/${id}`);
	public getApplicationLatest = async () =>
		this.instance.get<ApplicationHeadDTO>(`/application/head/latest`);
	public getUnhandledApplicationRows = async () =>
		this.instance.get<ApplicationRowExDTO[]>(`/application/rows/unhandled`); //requires user to be admin.

	//POST
	public saveApplication = async (application: ApplicationHeadDTO) =>
		this.instance.post<ApplicationHeadDTO>(
			`/application/head`,
			application
		);
	//if no id is set, creates a new one.
	public saveVerdict = async (verdict: VerdictDTO) =>
		this.instance.post<VerdictDTO>(`/application/verdict`, verdict);
	public setApplicationStatus = async (
		id: number,
		status: ApplicationStatus
	) =>
		this.instance.post<ActionResult>(
			`/application/head/setStatus/${id}/${status}`
		);
	public getMappingHistory = async (courseMappingHeadId: number) =>
		this.instance.get<VerdictDTO[]>(
			`/application/verdict/byCourseMappingHeadId/${courseMappingHeadId}`
		);
	////#endregion

	//#region School
	//GET
	public getSchools = async () =>
		this.instance.get<SchoolDTO[]>(`/school/all`);
	public getSchoolsById = async (id: number) =>
		this.instance.get<SchoolDTO[]>(`/school/byCountryId/${id}`);
	public getSchoolById = async (id: number) =>
		this.instance.get<SchoolDTO>(`/school/${id}`);
	public getCountries = async () =>
		this.instance.get<CountryDTO[]>(`/school/country/all`);

	//POST
	public saveSchool = async (school: SchoolDTO) =>
		this.instance.post<SchoolDTO>(`/school`, school); //if no id is set, creates a new one.
	//#endregion

	//#region Course
	//GET
	public getCoursesBySchoolId = async (id: number) =>
		this.instance.get<CourseDTO[]>(`/course/bySchool/${id}`);
	public getCourses = async () =>
		this.instance.get<CourseDTO[]>(`/course/all`);
	public getCourseById = async (id: number) =>
		this.instance.get<CourseDTO>(`/course/${id}`);

	//POST
	public saveCourse = async (course: CourseDTO) =>
		this.instance.post<CourseDTO>(`/course`, course); //if no id is set, creates a new one.
	//#endregion

	//#region Document
	public downloadDocumentById = async (id: number) =>
		this.instance.get(`/document/${id}`, { responseType: 'stream' });
	public uploadDocument = async (document: DocumentDTO) =>
		this.instance.post<DocumentDTO>(`/document`);
	public uploadDocuments = async (documents: DocumentDTO[]) =>
		this.instance.post<DocumentDTO[]>(`/document/many`);

	////#endregion

	//#region Report
	public downloadApplicationHeadById = (id: number | string) =>
		`${this.baseURL}/report/applicationhead/${id}`;
	//#endregion

	//#region User
	//GET
	public getUsers = async () => this.instance.get<UserDTO[]>(`/user/all`);
	public getUserById = async (id?: number) =>
		this.instance.get<UserDTO>(`/user/${id}`);

	//POST
	public saveUser = async (user: UserDTO) =>
		this.instance.post<UserDTO>(`/user`, user); //if no id is set, creates a new one.
	//#endregion

	//SESSION
	public login = async () => this.instance.get(`session/login`);
	public logout = async () => this.instance.post(`session/logout`);
	public getCurrentUser = async () =>
		this.instance.get<UserDTO>(`session/user`);

	//#region Settings
	//GET
	public getMasterTracks = async () =>
		this.instance.get<MasterTrackDTO[]>(`/setting/mastertracks`);
	public getMasterTrack = async (id: number) =>
		this.instance.get<MasterTrackDTO>(`/setting/mastertrack/${id}`);
	public saveMasterTrack = async (data: MasterTrackDTO) =>
		this.instance.post<MasterTrackDTO>(`/setting/mastertrack`, data);

	//#endregion
}
