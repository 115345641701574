import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { GridColDef, DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';

// import { APIService } from "../services/apiService";
import { Container } from '../components/_atoms';
import { ROUTES } from '../constants';
import { CourseDTO } from '../DTO/CourseDTO';
import { APIService } from '../services/apiService';
import { FormGroup, Typography } from '@material-ui/core';
import { levelName, levelNames } from '../util/enumerations';

const colDefs: GridColDef[] = [
	{ field: 'id', headerName: 'ID', type: 'number', flex: 0.5, minWidth: 50 },
	{
		field: 'schoolId',
		headerName: 'School ID',
		type: 'number',
		flex: 0.8,
		minWidth: 100,
	},
	{ field: 'code', headerName: 'Code', flex: 1, minWidth: 100 },
	{ field: 'title', headerName: 'Title', flex: 1, minWidth: 200 },
	{
		field: 'localCredits',
		headerName: 'Credits',
		type: 'number',
		flex: 0.5,
		minWidth: 125,
	},
	{
		field: 'level',
		headerName: 'Level',
		type: 'singleSelect',
		valueOptions: levelNames(),
		valueGetter: (params) => levelName(Number(params.value)),
		flex: 1,
		minWidth: 100,
	},
	{
		field: 'coursePage',
		headerName: 'Open',
		flex: 1,
		minWidth: 50,
		renderCell: (params) => (
			<Button
				variant="contained"
				color="primary"
				size="small"
				component={Link}
				to={`${ROUTES.COURSE}/${params.row.id}`}
			>
				Open
			</Button>
		),
	},
];

const Courses = () => {
	const match = useRouteMatch<{ schoolId: string }>();
	const [schoolId, setSchoolId] = useState(
		Number(match.params.schoolId) || 0
	);
	const [courses, setCourses] = useState<CourseDTO[]>([]);
	const api = new APIService();

	const getCourses = (newSchoolId: number = schoolId) => {
		setSchoolId(newSchoolId);
		if (newSchoolId > 0) {
			api.getCoursesBySchoolId(newSchoolId).then((data) => {
				setCourses(data);
			});
		} else {
			api.getCourses().then((data) => {
				setCourses(data);
			});
		}
	};

	useEffect(() => {
		getCourses();
	}, []);

	return (
		<>
			<Typography
				gutterBottom
				style={{ marginTop: '5px' }}
				variant="h4"
				align="center"
			>
				Courses
			</Typography>
			<FormGroup row>
				<div>
					<Button
						to={{
							pathname: `${ROUTES.COURSE}/0`,
							state: { schoolId },
						}}
						component={Link}
						size="small"
						style={{ float: 'right', marginLeft: '4px' }}
						color="secondary"
						variant="contained"
					>
						Create new
					</Button>
					<Button
						to={{
							pathname: `${ROUTES.SCHOOLS}`,
							state: { schoolId },
						}}
						component={Link}
						size="small"
						style={{ float: 'right', marginLeft: '4px' }}
						color="primary"
						variant="contained"
					>
						Show all schools
					</Button>
					{schoolId > 0 && (
						<Button
							to={{
								pathname: `${ROUTES.SCHOOLS}`,
								state: { schoolId },
							}}
							component={Link}
							size="small"
							style={{ float: 'right', marginLeft: '4px' }}
							color="primary"
							variant="contained"
						>
							Show all courses
						</Button>
					)}
				</div>
			</FormGroup>
			<Container>
				<div
					style={{
						height: 'calc(100vh - 230px)',
						width: '100%',
						marginTop: '10px',
					}}
				>
					<DataGrid
						columns={colDefs}
						rows={courses}
						components={{
							Toolbar: GridToolbar,
						}}
					/>
				</div>
			</Container>
		</>
	);
};

export default Courses;
