import { VerdictStatus } from '../util/enumerations';
import { BaseDTO, IBaseDTO } from './BaseDTO';

export interface IVerdictDTO extends IBaseDTO {
	id: number;
	date?: Date;
	applicationRowId: number;
	courseMappingHeadId: number;
	note?: string;
	status: VerdictStatus;
	applicationHeadId?: number;
}

export class VerdictDTO extends BaseDTO implements IVerdictDTO {
	id: number;
	applicationRowId: number;
	courseMappingHeadId: number;
	note?: string;
	status: VerdictStatus;
	applicationHeadId?: number;

	constructor(applicationRowId: number, courseMappingHeadId: number) {
		super();
		this.id = 0;
		this.note = '';
		this.applicationRowId = applicationRowId;
		this.courseMappingHeadId = courseMappingHeadId;
		this.status = VerdictStatus.Unhandled;
	}
}
