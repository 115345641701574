import { Typography } from '@material-ui/core';
import React from 'react';
import { getDateAndTime } from '../util/dateHelper';

interface ILastEditedProps {
	data:
		| {
				[key: string]: any;
				created: Date;
				createdBy: string;
				modified: Date;
				modifiedBy: string;
		  }
		| any;
}

const LastEdited = (props: ILastEditedProps) => {
	if (!props) return null;

	const { created, createdBy, modified, modifiedBy } = props.data;

	function Created() {
		if (created && createdBy) {
			return (
				<Typography>
					{'Created by '} <strong>{createdBy}</strong> {' at '}
					{getDateAndTime(created)}
				</Typography>
			);
		} else {
			return null;
		}
	}

	function Modified() {
		if (modified && modifiedBy) {
			return (
				<Typography>
					{'Last modified by '} <strong>{modifiedBy}</strong> {' at '}
					{getDateAndTime(modified)}
				</Typography>
			);
		} else {
			return null;
		}
	}

	return (
		<div
			style={{
				textAlign: 'left',
				marginTop: '10px',
				marginBottom: '10px',
			}}
		>
			<Created></Created>
			<Modified></Modified>
		</div>
	);
};

export default LastEdited;
