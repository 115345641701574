import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { APIService } from '../services/apiService';

import { ROUTES } from '../constants';
import { MasterTrackDTO } from '../DTO/MasterTrackDTO';
import { Container } from '../components/_atoms';
import {
	Button,
	FormGroup,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import LastEdited from './lastEdited';
import { EntityState, States } from '../util/enumerations';
import { useIsDirty } from '../hooks/isDirty';

const api = new APIService();

const MasterTrack = () => {
	const match = useRouteMatch<{ id?: string }>();
	const history = useHistory();
	const trackId: number = Number(match.params.id) || 0;
	const [masterTrack, setMasterTrack] = useState<MasterTrackDTO | null>(null);
	const [error, setError] = useState({ message: '' });
	const [isDirty, updateCleanValue] = useIsDirty(masterTrack);

	const setName = (name: string) => {
		setMasterTrack((prev) => prev && { ...prev, name });
	};
	const setState = (state: EntityState) => {
		setMasterTrack((prev) => prev && { ...prev, state });
	};

	const onSave = () => {
		if (masterTrack) {
			api.saveMasterTrack(masterTrack)
				.then((newTrack) => {
					setMasterTrack(newTrack);
					updateCleanValue(newTrack);
					setError({ message: '' });
					if (trackId === 0) {
						history.replace(`${ROUTES.MASTERTRACK}/${newTrack.id}`);
					}
				})
				.catch((error) => {
					setError({
						message: 'Error: ' + error.response.data.message,
					});
				});
		}
	};

	useEffect(() => {
		if (trackId > 0) {
			api.getMasterTrack(trackId).then((data) => {
				updateCleanValue(data);
				setMasterTrack(data);
			});
		} else {
			setMasterTrack(new MasterTrackDTO(trackId));
		}
	}, []);

	return (
		<>
			<h1>
				{masterTrack
					? masterTrack.id
						? `${masterTrack.name}`
						: 'Create track'
					: 'Loading track...'}
			</h1>
			{masterTrack && (
				<Container>
					<FormGroup row>
						<TextField
							type="text"
							value={masterTrack?.name}
							label="Name"
							variant="outlined"
							onChange={(e) => setName(e.target.value)}
						/>
						<Select
							variant="outlined"
							label="Status"
							value={masterTrack.state}
							onChange={(e) =>
								setState(e.target.value as EntityState)
							}
						>
							{States.map((s) => (
								<MenuItem value={s.id} key={s.id}>
									{s.name}
								</MenuItem>
							))}
						</Select>
					</FormGroup>
					<FormGroup row>
						<LastEdited data={masterTrack}></LastEdited>
					</FormGroup>
					<p>{error.message}</p>
					<FormGroup row>
						<Button
							variant="contained"
							color="primary"
							size="small"
							type="submit"
							value="save"
							onClick={(e) => onSave()}
							disabled={!isDirty}
						>
							Save
						</Button>
					</FormGroup>
				</Container>
			)}
		</>
	);
};

export default MasterTrack;
