import { IBaseDTO, BaseDTO } from './BaseDTO';
import { CountryDTO } from './CountryDTO';

export interface ISchoolDTO extends IBaseDTO {
	id: number;
	name: string;
	country: CountryDTO;
}

export class SchoolDTO extends BaseDTO implements ISchoolDTO {
	id: number;
	name: string;
	country: CountryDTO;

	constructor() {
		super();
		this.id = 0;
		this.name = '';
	}
}
