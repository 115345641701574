import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { Container } from '../components/_atoms';
import { ROUTES } from '../constants';
import { UserDTO } from '../DTO/UserDTO';
import { APIService } from '../services/apiService';
import { AdminLevels } from '../util/enumerations';
import LastEdited from '../components/lastEdited';

import {
	Button,
	FormControl,
	FormGroup,
	InputLabel,
	makeStyles,
	MenuItem,
	TextField,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { useIsDirty } from '../hooks/isDirty';

const useStyles = makeStyles((theme) => ({
	field: {
		marginBottom: '12px',
		marginRight: '12px',
	},
	button: {
		margin: '12px 6px 18px 0',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const User = () => {
	const match = useRouteMatch<{ id?: string }>();
	const userId: number = Number(match.params.id) || 0;
	const api = new APIService();
	const classes = useStyles();

	const [user, setUser] = useState<UserDTO | null>(null);
	const [error, setError] = useState({ message: '' });
	const [isDirty, updateCleanValue] = useIsDirty(user);

	useEffect(() => {
		if (userId > 0) {
			api.getUserById(userId).then((data) => {
				setUser(data);
				updateCleanValue(data);
			});
		} else {
			const newUser = new UserDTO();
			setUser(newUser);
			updateCleanValue(newUser);
		}
	}, []);

	const handleSubmit = (event: React.FormEvent) => {
		if (!user) return;
		api.saveUser(user)
			.then((data) => {
				setUser(data);
				setError({ message: '' });
			})
			.catch((error) => {
				setError({ message: 'Error: ' + error.response.data.message });
			});

		if (event) event.preventDefault();
	};

	return !user ? (
		<h1>Could not find user</h1>
	) : (
		<Container>
			<h1>{user.name}</h1>
			<form onSubmit={handleSubmit}>
				<FormGroup row>
					<TextField
						type="text"
						value={user.id}
						inputProps={{ readOnly: true }}
						label="Id"
						className={classes.field}
						variant="outlined"
					/>
					<TextField
						type="text"
						value={user.isAdmin ? 'Yes' : 'No'}
						inputProps={{ readOnly: true }}
						label="Admin"
						className={classes.field}
						variant="outlined"
					/>
				</FormGroup>
				<FormGroup row>
					<FormControl
						variant="outlined"
						className={classes.formControl}
					>
						<InputLabel id="level-select-label">Admin</InputLabel>
						<Select
							label="Admin"
							value={user.isAdmin ? '1' : '0'}
							className={classes.field}
							labelId="level-select-label"
							onChange={(e) =>
								setUser(
									(prevUser) =>
										({
											...prevUser,
											isAdmin: Boolean(
												Number(e.target.value)
											),
										} as UserDTO)
								)
							}
						>
							{AdminLevels.map((u) => (
								<MenuItem
									value={u.isAdmin ? '1' : '0'}
									key={u.id}
								>
									{u.isAdmin ? 'Yes' : 'No'}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</FormGroup>
				<br />
				<LastEdited data={user}></LastEdited>
				<Button
					variant="contained"
					color="primary"
					size="small"
					className={classes.button}
					type="submit"
					value="save"
					disabled={!isDirty}
				>
					Save
				</Button>
				<p>{error.message}</p>
			</form>
		</Container>
	);
};

export default User;
