import React from 'react';

import {
	Accordion,
	FormGroup,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccordionDetails } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CourseDTO } from '../../../DTO/CourseDTO';
import { EntityState } from '../../../util/enumerations';
import { useApplication, useUpdateApplication } from '..';

const useStyles = makeStyles({
	accordion: {
		border: '1px solid #999',
		backgroundColor: '#f6f6f6',
		boxShadow: 'none',
		marginBottom: '8px',
		borderRadius: '8px !important',
	},
});

type InformationRowProps = {
	coursesExchange: CourseDTO[];
	rowIndex: number;
};

const InformationRows = ({
	coursesExchange,
	rowIndex,
}: InformationRowProps) => {
	const classes = useStyles();
	const { privileges } = useApplication();
	const { updateInformationRow, updateInformationRowUrl } =
		useUpdateApplication();
	return (
		<>
			{coursesExchange
				.filter(
					(c) =>
						c.informationRow &&
						c.informationRow.state !== EntityState.Removed
				)
				.map((course, idx) => (
					<Accordion
						defaultExpanded={privileges.editRows}
						key={idx}
						className={classes.accordion}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Grid container spacing={0} key={idx}>
								<Grid item xs={10}>
									<Typography>
										Course information
										<strong>{`(${course?.code} - ${course?.title})`}</strong>
									</Typography>
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails>
							<div style={{ width: '100%' }}>
								<FormGroup row>
									<TextField
										id="outlined-multiline-static"
										label="Description"
										multiline
										fullWidth
										rows={4}
										inputProps={{
											readOnly: !privileges.editRows,
										}}
										placeholder="Describe the course..."
										variant="outlined"
										value={course.informationRow?.note}
										onChange={(e) => {
											updateInformationRow(
												rowIndex,
												e.target.value,
												idx
											);
										}}
									/>
								</FormGroup>
								<FormGroup row>
									<TextField
										id="outlined-multiline-static"
										label="Course Description Link"
										fullWidth
										style={{ marginTop: '10px' }}
										inputProps={{
											readOnly: !privileges.editRows,
										}}
										placeholder="Link to course description..."
										variant="outlined"
										value={
											course.informationRow?.externalUrl
										}
										onChange={(e) => {
											updateInformationRowUrl(
												rowIndex,
												e.target.value,
												idx
											);
										}}
									/>
								</FormGroup>
							</div>
						</AccordionDetails>
					</Accordion>
				))}
		</>
	);
};

export default InformationRows;
