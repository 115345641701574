export const APPLICATION_STATUS = {
	PENDING: 'PENDING',
	DRAFT: 'DRAFT',
};

export const ROLES = {
	ADMIN: 'ADMIN',
	STUDENT: 'STUDENT',
};

export const ROUTES = {
	APPLICATIONS: '/applications',
	APPLICATIONS_ADMIN: '/applications-admin',
	APPLICATION: '/application',
	CREATE_APPLICATION: '/create',
	APPLICATION_ROWS: '/application-rows',
	SCHOOLS: '/schools',
	SCHOOL: '/school',
	USERS: '/users',
	USER: '/user',
	COURSE: '/course',
	COURSES: '/courses',
	LOGIN: '/login',
	MASTERTRACK: '/mastertrack',
	MASTERTRACKS: '/mastertracks',
};

export const HOME_SCHOOL_ID = 1;
