import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import { makeStyles } from '@material-ui/core/styles';

import ApplicationRow from './row';
import { EntityState } from '../../../util/enumerations';
import { Button, Card, IconButton } from '@material-ui/core';
import { HOME_SCHOOL_ID } from '../../../constants';
import { useSchools } from '../../../contexts';
import { useApplication, useUpdateApplication } from '..';

const useStyles = makeStyles({
	addButton: {
		marginTop: '12px',
	},
	card: {
		padding: '10px',
		marginBottom: '20px',
		marginTop: '50px',
		border: '1px solid #ababab',
	},
});

const ApplicationRows = () => {
	const classes = useStyles();
	const { application, privileges } = useApplication();
	const { removeRow, addRow } = useUpdateApplication();
	const schools = useSchools();
	const appSchools = {
		home: schools?.find((s) => s.id === HOME_SCHOOL_ID) || null,
		exchange: application.school || null,
	};

	const renderApplicationRows = React.useMemo(
		() => (
			<div>
				{application.applicationRows.map((row, idx) => {
					if (row.state === EntityState.Removed) {
						return (
							<div style={{ textAlign: 'center' }} key={idx}>
								<br />
								<IconButton
									style={{ margin: '10px' }}
									onClick={() =>
										removeRow(idx, EntityState.Active)
									}
								>
									<UndoIcon fontSize="large" />
								</IconButton>
							</div>
						);
					}
					return (
						<Card elevation={5} className={classes.card} key={idx}>
							<div style={{ textAlign: 'right' }}>
								<strong>#{idx + 1}</strong>
							</div>
							<ApplicationRow
								row={row}
								schools={appSchools}
								rowIndex={idx}
							/>
							{privileges.editRows && (
								<div style={{ textAlign: 'right' }}>
									<IconButton
										color="secondary"
										aria-label="delete"
										onClick={() => removeRow(idx)}
									>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</div>
							)}
						</Card>
					);
				})}
			</div>
		),
		[
			application.applicationRows,
			appSchools,
			privileges.editRows,
			removeRow,
		]
	);

	return (
		<div>
			{schools && application?.school?.id ? (
				<>
					<div>{renderApplicationRows}</div>
					{privileges.editRows && (
						<div style={{ textAlign: 'center' }}>
							<Button
								color="primary"
								variant="contained"
								onClick={() => addRow()}
								className={classes.addButton}
							>
								Add row
							</Button>
						</div>
					)}
				</>
			) : null}
		</div>
	);
};

export default ApplicationRows;
