export const isEmpty: (str: string) => boolean = (str) => str.length === 0;

export const isEmail: (email: string) => boolean = (email) =>
	email.match(
		//eslint-disable-next-line
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	)
		? true
		: false;

export const isEnterKey: (e: React.KeyboardEvent<HTMLInputElement>) => boolean =
	(e) => e.keyCode === 13 || e.which === 13;

export const getApiPath = () => {
	if (!isDevEnv()) {
		return '/api';
	} else {
		return 'http://localhost:3001/api';
	}
};

export const isDevEnv = () => process.env.REACT_APP_DEVELOPMENT === 'true';
