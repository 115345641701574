import { BaseDTO } from './BaseDTO';

export interface MasterTrackDTO extends BaseDTO {
	id: number;
	name: string;
}

export class MasterTrackDTO extends BaseDTO implements MasterTrackDTO {
	id: number;
	name: string;

	constructor(trackId: number) {
		super();
		this.id = trackId;
	}
}
