import { EntityState } from '../util/enumerations';

export interface IBaseDTO {
	created: Date;
	createdBy: string;
	modified: Date;
	modifiedBy: string;
	state: EntityState;
	isModified: boolean | undefined;
}

export class BaseDTO implements IBaseDTO {
	created: Date;
	createdBy: string;
	modified: Date;
	modifiedBy: string;
	state: EntityState;
	isModified: boolean | undefined;

	constructor(useModifiedFlag = false) {
		this.state = EntityState.Active;
		this.createdBy = '';
		this.modifiedBy = '';
		this.isModified = useModifiedFlag ? true : undefined;
	}
}
