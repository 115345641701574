import React from 'react';

const isObject = (a: any): a is Object => typeof a == 'object' && a != null;

/**
 * Indicates whether the current version of a value is different from the last saved value
 * @param {Any}	   	 initialValue		 			The current value
 * @param {Object} 	 options
 * @param {Any} 	 options.initialComparisonValue value that will be used as inital value to compare against. Default is the same value as is passed in as current.
 * @param {Function} options.comparisonFunction 	function that can be used to override the default comparison function. Returns true if values are to be considered equal
 */
export const useIsDirty = <T extends unknown>(
	initialValue: T,
	options?: {
		initialComparisonValue: T;
		comparisonFunction?: (a: T, b: T) => boolean;
	}
): [boolean, (newValue: T) => void] => {
	const originalRef = React.useRef<T>(
		options?.initialComparisonValue || initialValue
	);

	const areDeeplyEqual = React.useCallback((a: any, b: any): boolean => {
		if (a === b) {
			return true;
		}
		if (isObject(a) && isObject(b)) {
			return (
				Object.keys(a).length === Object.keys(b).length &&
				Object.keys(a)
					.filter((key) => key !== 'isModified')
					.every(
						(key) =>
							b.hasOwnProperty(key) &&
							areDeeplyEqual(a[key], b[key])
					)
			);
		}
		return false;
	}, []);

	const updateCleanValue = React.useCallback(
		(newValue: T) => {
			originalRef.current = newValue;
		},
		[originalRef]
	);

	return [
		options?.comparisonFunction
			? !options.comparisonFunction(initialValue, originalRef.current)
			: !areDeeplyEqual(initialValue, originalRef.current),
		updateCleanValue,
	];
};
