import { ApplicationHeadDTO } from '../../DTO/ApplicationHeadDTO';

export interface IApplicationState {
	application: ApplicationHeadDTO;
	privileges: Privileges;
	validationMessage: string;
	isDirty: boolean;
	serverMessage: string | null;
}

export class ApplicationState implements IApplicationState {
	application: ApplicationHeadDTO;
	privileges: Privileges;
	validationMessage: string;
	isDirty: boolean;
	serverMessage: string | null;

	constructor(args: {
		privileges: Privileges;
		application?: ApplicationHeadDTO;
		validationMessage?: string;
		isDirty?: boolean;
		serverMessage?: string | null;
	}) {
		this.application = args.application || new ApplicationHeadDTO();
		this.privileges = args.privileges;
		this.validationMessage = args.validationMessage ?? '';
		this.isDirty = args.isDirty || false;
		this.serverMessage = args.serverMessage || null;
	}
}
