import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import { APIService } from "../services/apiService";
import { Container } from '../components/_atoms';
import { ROUTES } from '../constants';
import { APIService } from '../services/apiService';
import { ApplicationHeadDTO } from '../DTO/ApplicationHeadDTO';
import { GridColDef, DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getDateAndTime } from '../util/dateHelper';
import { Button, Input, Typography } from '@material-ui/core';
import { appStatusName, getAppStatusNames } from '../util/enumerations';

const api = new APIService();

const colDefs: GridColDef[] = [
	{
		field: 'edit',
		headerName: 'Open',
		minWidth: 110,
		renderCell: (params) => (
			<Button
				variant="contained"
				color="primary"
				size="small"
				component={Link}
				to={`${ROUTES.APPLICATION}/${params.row?.id}`}
			>
				Open
			</Button>
		),
	},
	{
		field: 'print',
		headerName: 'Print',
		minWidth: 110,
		renderCell: (params) => (
			<Button
				variant="contained"
				style={{ marginLeft: '10px' }}
				color="secondary"
				href={api.downloadApplicationHeadById(params.row?.id)}
			>
				Print
			</Button>
		),
	},
	{ field: 'id', headerName: 'ID', width: 50, type: 'number' },
	{
		field: 'userName',
		headerName: 'Student',
		type: 'string',
		width: 200,
		valueGetter: (params) => params.row?.user.name,
	},
	{
		field: 'schoolName',
		headerName: 'University',
		width: 140,
		valueGetter: (params) => params.row?.school.name,
	},
	{
		field: 'status',
		headerName: 'Status',
		type: 'singleSelect',
		width: 120,
		valueOptions: getAppStatusNames(),
		valueGetter: (params) => appStatusName(params.row?.status),
	},
	{ field: 'total', headerName: 'Total rows', width: 150 },
	{ field: 'unhandled', headerName: 'Unhandled rows', width: 100 },
	{
		field: 'created',
		type: 'date',
		headerName: 'Created',
		width: 200,
		valueGetter: (params) =>
			params.value ? new Date(params.value as string) : null,
	},
	{
		field: 'modified',
		headerName: 'Modified',
		type: 'date',
		width: 200,
		valueGetter: (params) =>
			params.value ? new Date(params.value as string) : null,
	},
];

const ApplicationsAdmin = () => {
	const [applications, setApplications] = useState<
		ApplicationHeadDTO[] | null
	>(null);

	const getApplications = () => {
		api.getApplications().then((data) => setApplications(data));
	};

	useEffect(() => {
		getApplications();
	}, []);

	if (!applications) return <p>Fetching applications...</p>;
	return (
		<Container>
			<Typography
				gutterBottom
				style={{ marginTop: '5px' }}
				variant="h4"
				align="center"
			>
				Applications
			</Typography>
			<div
				style={{
					height: 'calc(100vh - 200px)',
					width: '100%',
					marginTop: '10px',
				}}
			>
				<DataGrid
					columns={colDefs}
					components={{
						Toolbar: GridToolbar,
					}}
					rows={applications}
				/>
			</div>
		</Container>
	);
};

export default ApplicationsAdmin;
