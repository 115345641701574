import axios, { AxiosInstance, AxiosResponse } from 'axios';

declare module 'axios' {
	//interface AxiosResponse<T = any> extends Promise<T> {}
	export interface AxiosInstance {
		request<T = any>(config: AxiosRequestConfig): Promise<T>;
		get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
		delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
		head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
		post<T = any>(
			url: string,
			data?: any,
			config?: AxiosRequestConfig
		): Promise<T>;
		put<T = any>(
			url: string,
			data?: any,
			config?: AxiosRequestConfig
		): Promise<T>;
		patch<T = any>(
			url: string,
			data?: any,
			config?: AxiosRequestConfig
		): Promise<T>;
	}
}

export abstract class HttpService {
	protected readonly instance: AxiosInstance;
	protected baseURL: string;

	public constructor(baseURL: string) {
		this.baseURL = baseURL;
		this.instance = axios.create({
			baseURL,
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
		});
		this._initializeResponseInterceptor();
	}

	private _initializeResponseInterceptor = () => {
		this.instance.interceptors.response.use(
			this._handleResponse,
			this._handleError
		);
	};

	private _handleResponse = ({ data }: AxiosResponse) => data;

	protected _handleError = (error: any) => Promise.reject(error);
}
