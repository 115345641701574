import {
	Button,
	Card,
	FormGroup,
	IconButton,
	TextField,
	Typography,
} from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { Link } from 'react-router-dom';
import { ROUTES } from '../constants';
import { ApplicationHeadDTO } from '../DTO/ApplicationHeadDTO';
import { APIService } from '../services/apiService';
import { appStatusDescription, appStatusName } from '../util/enumerations';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useAuth } from '../contexts/auth';

const api = new APIService();
const margin = {
	marginBottom: '12px',
	marginRight: '12px',
};

const Home = () => {
	const { user } = useAuth();
	const [latestApplication, setLatestApplication] =
		React.useState<ApplicationHeadDTO | null>(null);

	React.useEffect(() => {
		if (user) {
			api.getApplicationLatest().then((app) => setLatestApplication(app));
		}
	}, [user]);
	if (user) {
		return (
			<div>
				<Typography
					gutterBottom
					style={{ marginTop: '5px' }}
					variant="h4"
					align="center"
				>
					Welcome, {user.name}
				</Typography>
				{latestApplication ? (
					<>
						<FormGroup row>
							<Alert severity="info" style={{ width: '100%' }}>
								<AlertTitle>Status</AlertTitle>
								{appStatusDescription(latestApplication.status)}
							</Alert>
						</FormGroup>
						<Card
							elevation={5}
							style={{
								padding: '10px',
								marginBottom: '20px',
								marginTop: '10px',
							}}
						>
							<FormGroup
								row
								style={{
									display: 'flex',
									justifyContent: 'center',
									margin: '20px',
								}}
							>
								<TextField
									type="text"
									value={latestApplication?.school?.name}
									inputProps={{ readOnly: true }}
									label="University"
									style={margin}
								/>
								<TextField
									type="text"
									value={
										latestApplication?.school?.country?.name
									}
									label="Country"
									style={margin}
									inputProps={{ readOnly: true }}
								/>
								<TextField
									type="text"
									value={latestApplication?.year}
									label="Year"
									style={margin}
									inputProps={{ readOnly: true }}
								/>
								<TextField
									type="text"
									value={appStatusName(
										latestApplication?.status
									)}
									label="Status"
									style={margin}
									inputProps={{ readOnly: true }}
								/>
								<Link
									to={`${ROUTES.APPLICATION}/${latestApplication.id}`}
									key={latestApplication.id}
									className="no-link-style"
								>
									<IconButton
										aria-label="delete"
										color="primary"
									>
										<EditIcon fontSize="large" />
									</IconButton>
								</Link>
							</FormGroup>
						</Card>
					</>
				) : (
					<>
						<FormGroup row>
							<Alert severity="info" style={{ width: '100%' }}>
								<AlertTitle>Status</AlertTitle>
								Please continue by creating a new application
							</Alert>
						</FormGroup>
						<div style={{ textAlign: 'center' }}>
							<Link
								to={`${ROUTES.CREATE_APPLICATION}`}
								key={'allApplications'}
								className="no-link-style"
							>
								<Button
									variant="contained"
									color="secondary"
									size="large"
									style={{
										margin: '20px',
									}}
									startIcon={<AddCircleOutlineIcon />}
								>
									Create application
								</Button>
							</Link>
						</div>
					</>
				)}
			</div>
		);
	} else {
		return (
			<div style={{ textAlign: 'center' }}>
				<Typography
					gutterBottom
					style={{ marginTop: '5px' }}
					variant="h4"
					align="center"
				>
					Welcome
				</Typography>
				<Typography>
					Please continue by logging in using your KTH credentials.
				</Typography>
			</div>
		);
	}
};

export default Home;
